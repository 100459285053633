import { useState } from "react";
import { Chips } from "primereact/chips";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCreateResourceMutation } from "../services/resourceApi";

const AddResource = () => {
  const { userRole } = useSelector((state) => state.auth);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [topics, setTopics] = useState([]);
  const [file, setFile] = useState("");

  const [createResource, { isLoading, isError }] = useCreateResourceMutation();
  const [stringTopic, setStringTopic] = useState("");
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile([file]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("description", description);
    formData.append("subject", subject);
    formData.append("grade", grade);
    file.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("title", title);

    let trimmedString = stringTopic.trim();
    let topicsArray = trimmedString.split(/\s*,\s*/);

    topicsArray.forEach((tag) => {
      if (tag !== "") {
        formData.append("topics", tag);
      }
    });
    topics.forEach((topic) => {
      formData.append("topics", topic);
    });

    if (isError) {
      toast.error("Error occurred!");
    }

    try {
      if (!isLoading) {
        const response = await createResource(formData).unwrap();
        if (response) {
          toast.success("You have created post successfully!");
          setTimeout(() => {
            navigate("/resources");
          }, 1000);
        }
      }

      setDescription("");
      setFile([]);
      setTopics([]);
      setStringTopic("");
    } catch (error) {
      console.log(error);
      const message = error?.data?.error || "Something went wrong!";
      toast.error(message);
    }
  };

  if (userRole !== "staff") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-2xl text-gray-500">
          You are not authorized to access this page
        </p>
        <p className="text-sm my-4 text-gray-500">
          redirecting to home page...
        </p>
        {setTimeout(() => {
          navigate("/");
        }, 4000)}
      </div>
    );
  }

  const descriptionWordCount = description.trim().split(/\s+/).length;
  return (
    <form
      className="max-w-lg mx-auto my-40 w-full px-6"
      onSubmit={handleSubmit}
    >
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="title"
        >
          Title:
          <span className="text-xs text-gray-500 font-normal">
            Please limit your topic to a maximum of 3 words, for example, "Tense
            Short Note."
          </span>
        </label>
        <input
          required
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="description"
        >
          Description:
          <span className="text-xs text-gray-500 font-normal">
            We recommend writing a description of around 25 to 35 words, but
            feel free to write as much as you'd like. You can use ChatGPT for
            content generation.
            <span className="font-bold">
              Please don't forget to mention whether the PDF is a short note, a
              book, or another type of document.
            </span>
          </span>
        </label>

        <textarea
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full h-32 resize-none"
          id="description"
          value={description}
          onChange={handleDescriptionChange}
        />
        <p className="text-end text-gray-400 text-sm">
          {descriptionWordCount} words
        </p>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="grade"
        >
          Grade:
        </label>
        <select
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="grade"
          value={grade}
          onChange={handleGradeChange}
        >
          <option value="">Select Grade</option>
          <option value="Grade 7 & 8">Grade 7 & 8</option>
          <option value="Grade 9 & 10">Grade 9 & 10</option>
          <option value="Grade 11 & 12">Grade 11 & 12</option>
          <option value="Freshman">Freshman</option>
          <option value="General">General</option>
        </select>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="subject"
        >
          Subject:
        </label>
        <select
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="subject"
          value={subject}
          onChange={handleSubjectChange}
        >
          <option value="">Select Subject</option>
          <option value="Aptitude">Aptitude</option>
          <option value="Biology">Biology</option>
          <option value="Chemistry">Chemistry</option>
          <option value="Economics">Economics</option>
          <option value="English">English</option>
          <option value="Geography">Geography</option>
          <option value="History">History</option>
          <option value="Mathematics">Mathematics</option>
          <option value="Physics">Physics</option>
          <option value="General Knowledge">General Knowledge</option>
        </select>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="topics"
        >
          Topics:
          <span className="text-xs text-gray-500 font-normal">
            Add more descriptive topics. Use comma[on Mobile] or enter key [on
            PC] as separator for topics. Eg. "Tense, Grammar"
          </span>
        </label>
        <div className="hidden md:inline-block">
          <Chips
            value={topics}
            onChange={(e) => setTopics(e.value)}
            separator=","
          />
        </div>
        <input
          className="md:hidden border w-full border-gray-300 rounded-md p-2 mb-2 outline-none focus:border-gray-500"
          type="text"
          onChange={(e) => setStringTopic(e.target.value)}
        />
      </div>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="topics"
      >
        Pdf resource:
        <span className="text-xs text-gray-500 font-normal">
          Please upload the file in PDF format and ensure that it is less than
          10MB in size."
        </span>
      </label>
      <input
        required
        className="border w-full border-gray-300 rounded-md p-2 mb-2"
        type="file"
        id="file"
        onChange={handleImageChange}
      />

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        {isLoading ? "Submitting..." : "Submit"}
      </button>

      <ToastContainer />
    </form>
  );
};

export default AddResource;
