import React, { useState } from "react";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Modal from "./Modal2";

const Donate = ({ open, setOpen }) => {
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  let timeoutId = null;

  const handleDonate = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`https://backend.alberttutorial.com/api/payment`, {
        amount: amount * 100,
      });

      const clientSecret = response.data.clientSecret;

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        console.error(result.error.message);
        setMessage(result.error.message);
      } else if (result.paymentIntent.status === "succeeded") {
        setMessage("Donation successful! Thank you for your support.");
        setTimeout(()=>{
          setOpen(false)
        },[2000])
        
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data.error : error.message
      );
      setMessage(
        "There was an issue processing your donation. Please try again."
      );
    } finally {
      setLoading(false);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setMessage(null);
      }, 4000);
    }
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleDonate}>
        <h2 className="font-semibold">Make a Donation</h2>
        {message && typeof message === "string" && (
          <p
            className={
              message.includes("successful") ? "text-green-500" : "text-red-500"
            }
          >
            {message}
          </p>
        )}
        <input
          className="p-1 rounded w-full my-3 border border-gray-300 outline-none"
          type="number"
          placeholder="Enter amount in USD"
          value={amount}
          onChange={(e) => setAmount(parseFloat(e.target.value))}
          required
        />
        <CardElement className="p-2 bg-white rounded w-full my-3 border border-gray-300" />
        <button
          className="mt-5 bg-primary px-3 py-1 text-white font-medium rounded"
          type="submit"
          disabled={!stripe || loading}
        >
          {loading ? "Processing..." : "Donate"}
        </button>
      </form>
    </Modal>
  );
};

export default Donate;
