import { MdVerified } from "react-icons/md";


const UserCard = ({ createdBy }) => {
  let memberType = ""
  if (createdBy?.role === "staff") {
    memberType = "Staff"
  } else if (createdBy?.role === "admin") {
    memberType = "Admin"
  } else if (createdBy?.role === "community"){
    memberType = "Community"
  }
  return (
    <div className="flex items-center rounded space-x-3 px-3 pl-0">
      <img
        className="w-10 h-10 object-cover rounded-full border"
        src={createdBy?.avatar}
        alt={createdBy?.name}
      />
      <div>
        <div className="text-sm text-gray-700 flex space-x-2 items-center"><p>{createdBy?.name}</p> {createdBy?.role === "staff" && <MdVerified className="text-primary" />} </div>{" "}
        <p className="text-gray-400 text-xs">{memberType} member</p>
      </div>
     
    </div>
  );
};

export default UserCard;
