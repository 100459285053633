import { Link } from "react-router-dom";
import {
  RiTelegramLine,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiFacebookCircleLine,
  RiTwitterLine,
} from "react-icons/ri";

const SocialMediaContact = () => {
  return (
    <div className="flex text-gray-600  text-xl my-5 py-3 border-b">
      <Link
        to="https://t.me/albert_tutorial_2014"
        target="_blank"
        rel="noopener noreferrer"
        className="mr-5 hover:-translate-y-1.5 hover:text-gray-700 transition-all duration-700 rounded-full p-2 group  "
      >
        <RiTelegramLine />
      </Link>
      <Link
        to={"https://instagram.com/albert_tutorial?igshid=NTc4MTIwNjQ2YQ=="}
        target="_blank"
        rel="noopener noreferrer"
        className="mr-5 hover:-translate-y-1.5 hover:text-gray-700 transition-all duration-700 rounded-full p-2 group"
      >
        <RiInstagramLine />
      </Link>
      <Link
        to={
          "https://www.linkedin.com/company/albert-tutorial-and-volunteerism-center-atvc/"
        }
        target="_blank"
        rel="noopener noreferrer"
        className="mr-5 hover:-translate-y-1.5 hover:text-gray-700 transition-all duration-700 rounded-full p-2 group"
      >
        <RiTwitterLine />
      </Link>
      <Link
        to={
          "https://www.linkedin.com/company/albert-tutorial-and-volunteerism-center-atvc/"
        }
        target="_blank"
        rel="noopener noreferrer"
        className="mr-5 hover:-translate-y-1.5 hover:text-gray-700 transition-all duration-700 rounded-full p-2 group"
      >
        <RiLinkedinBoxFill />
      </Link>
      <Link
        to={"https://m.facebook.com/AlbertTutorialEthiopia"}
        target="_blank"
        rel="noopener noreferrer"
        className="group hover:-translate-y-1.5 hover:text-gray-700 transition-all duration-700 rounded-full p-2 "
      >
        <RiFacebookCircleLine />
      </Link>
    </div>
  );
};

export default SocialMediaContact;
