import Underline from "../../common/underline";

const Service = () => {
  const serviceImages = [
    {
      description: "Free Summer Tutoring",
      image: "../../image/landing/1.png",
    },
    {
      description: "Encourage Communication ",
      image: "../../image/landing/2.png",
    },
    {
      description: "Provide resources ",
      image: "../../image/landing/3.png",
    },
    {
      description: "Exam Preparation",
      image: "../../image/landing/4.png",
    },
    {
      description: "Charity",
      image: "../../image/landing/5.png",
    },
  ];
  return (
    <section className="my-10 ">
      <h2 className="flex justify-center uppercase header-3">
        What We provide
      </h2>
      <Underline color={"bg-primary"} />
      <div className="py-16 bg-gray-50 my-16">
        <p className="w-full sm:w-2/3 mx-auto px-3 text-center text-lg text-gray-600 pb-16">
          Albert is a dedicated educational institution that prioritizes giving
          back to the community. We are proud to offer Free Summer Tutoring
          programs, providing students with personalized support and guidance at
          no cost. Additionally, our commitment to charity initiatives allows us
          to make a positive impact by supporting causes that promote education
          and social well-being.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4 sm:px-16 items-center">
          {serviceImages.map((item,index) => {
            return (
              <div key={index} className="flex flex-col p-2 rounded shadow transition-all duration-700 hover:-translate-y-4">
                <div className="w-32 h-32 mx-auto ">
                  <img
                    className="object-cover"
                    src={item.image}
                    alt={item.description}
                  />
                </div>

                <p className="px-auto text-center w-100 font-semibold">
                  {item.description}
                </p>
              </div>
            );
          })}
          <div className=""></div>
        </div>
      </div>
    </section>
  );
};

export default Service;
