import { Link } from "react-router-dom";
import {
  RiTelegramLine,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiFacebookCircleLine,
  RiTwitterLine,
} from "react-icons/ri";

const Footer = () => {
  return (
    <div
      className={
        "bg-gray-950 flex flex-col text-gray-100 justify-center mt-auto"
      }
    >
      <img
        className="w-44 mx-auto py-6 object-contain"
        src="../../image/albert-logo.png"
        alt="albert logo"
      />
      <h3 className=" mx-auto font-semibold text-gray-300">
        Stay connected with us on social media!
      </h3>
      <div className="mx-auto flex text-gray-200 text-xl my-5 p-1">
        <Link
          to="https://t.me/albert_tutorial_2014"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-5 bg-primary hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group  "
        >
          <RiTelegramLine />
        </Link>
        <Link
          to={"https://instagram.com/albert_tutorial?igshid=NTc4MTIwNjQ2YQ=="}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-5 bg-primary hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group"
        >
          <RiInstagramLine />
        </Link>
        <Link
          to={
            "https://www.linkedin.com/company/albert-tutorial-and-volunteerism-center-atvc/"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="mr-5 bg-primary hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group"
        >
          <RiTwitterLine />
        </Link>
        <Link
          to={
            "https://www.linkedin.com/company/albert-tutorial-and-volunteerism-center-atvc/"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="mr-5 bg-primary hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group"
        >
          <RiLinkedinBoxFill />
        </Link>
        <Link
          to={"https://m.facebook.com/AlbertTutorialEthiopia"}
          target="_blank"
          rel="noopener noreferrer"
          className="group bg-primary hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 "
        >
          <RiFacebookCircleLine />
        </Link>
      </div>

      <div className={"bg-gray-700 h-px` mx-32"}></div>
      <div className="mx-auto text-white font-medium flex items-center space-x-2">
        ADAMA, ETHIOPIA
      </div>
      {/* <p className="mx-auto text-xs text-gray-400 py-2">
        albert.tutorial.vc@gmail.com | +251 96 590 4254
      </p> */}
      <div
        className={
          "bg-gray-900 py-2 mx-auto text-xs relative text-center text-white w-full "
        }
      >
        <p></p>
        <p>© 2023 | All rights reserved</p>

        <div className="text-gray-500 text-xs flex sm:absolute sm:bottom-2 sm:right-2">
          <p>Developed by</p>
          <Link
            to={"https://linkedin.com/in/jebessa-dejene-74a674221"}
            target="_blank"
            rel="noopener noreferrer"
            className="text-end text-gray-400 px-2 hover:text-gray-300"
          >
            Jebessa D.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
