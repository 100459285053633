import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiMail, FiLock, FiEye, FiEyeOff, FiArrowLeft } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../services/authApi";
import { setCredentials } from "../features/auth/authSlice";
import GoogleAuth from "../components/common/GoogleButton";
// import GoogleButton from "../components/common/GoogleButton";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useSelector((state) => state.auth);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return;
    }
    if (password.includes(" ")) {
      toast.error("Password must not contain space.");
      return;
    }
    const isValidPhoneNumberOrEmail = () => {
      const regex =
        /^(?:(?:\+251|0|251)?[79][0-9]{8}|[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+)$/;
      return regex.test(email);
    };

    if (!isValidPhoneNumberOrEmail()) {
      toast.error("Please fill a valid email or ethiopian phone number.");
      return;
    }

    if (email && password) {
      try {
        const user = await login({ email, password }).unwrap();

        dispatch(setCredentials(user));

        toast.success("Logged in successfully!");
        setTimeout(() => {
          const { from } = location.state || { from: { pathname: "/" } };
          if (from === "/posts") {
            navigate("/posts");
          } else {
            navigate("/");
          }
        }, 1000);
      } catch (error) {
        const message = error?.data?.message || "Something went wrong!";
        toast.error(message);
      }
    } else {
      toast.error("Please provide valid email and password.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-between bg-gray-100 sm:px-12 sm:pr-16">
      <div className="basis-1/2 hidden sm:block">
        <img
          src={"../image/login-image.png"}
          alt="login clip art"
          className=" w-125 h-125 ml-10"
        />
      </div>
      <div className="max-w-md w-full mx-auto p-6">
        <Link className="md:hidden" to={"/"}>
          <FiArrowLeft />
        </Link>
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold mb-2">Login</h2>
            <p className="text-gray-600">
              Don't have an account?{" "}
              <a href="/register" className="text-primary">
                Sign up
              </a>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiMail />
              </span>
              <input
                type="text"
                id="email"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Email or Phone number"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiLock />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="focus:outline-none ml-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-full flex justify-center items-center space-x-1 bg-primary text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <></>
            )}{" "}
            <p>Login</p>
          </button>
          <div class="inline-flex items-center my-6 justify-center w-full">
            <hr class="w-[70%] h-px mt-1.5 bg-gray-300 border-0" />
            <span class="absolute px-3 ml-12 -translate-x-1/2  bg-gray-100">
              or
            </span>
          </div>
          <div className="">
            <GoogleAuth />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
