// import educationImage from "../images/education.jpg";
import AboutCardLeft from "../components/pages/about/AboutCardLeft";
import AboutCardRight from "../components/pages/about/AboutCardRight";
import OurGallery from "../components/pages/about/OurGallery";
import OurMission from "../components/pages/about/OurMission";
import { Link } from "react-router-dom";

const data = [
  {
    title: "ESTABLISHMENT",
    year: "2012",
    description:
      "ATVC started with Biniyam Lombe's vision to provide equal educational opportunities for all. The program initially started with just two students at Adama No 2 School.",
    image: "../image/about/photo1.jpg",
  },
  {
    title: "Free Tutorials",
    year: "2012-Present",
    description:
      "ATVC has been offering free tutorial classes since its inception, benefiting over 3,000 students in Adama. Our dedicated team of over 55 volunteers provides comprehensive support, empowering students through education.",
    image: "../image/about/photo2.jpg",
  },
  {
    title: "Material Support",
    year: "2015-Present",
    description:
      "ATVC not only offers academic support but also provides material assistance to students from low-income families. Since 2015, the program has assisted more than 250 primary school students from seven different schools in Adama, helping them overcome financial barriers to education.",
    image: "../image/about/photo3.jpg",
  },
  {
    title: "Expanding Horizons",
    year: "2022-Present",
    description:
      "In 2022, ATVC introduced tutorial classes taught in Afan Oromo for students in grades 7 and 8. This expansion promotes inclusivity, allowing students to learn in their native language and fostering cultural diversity.",
    image: "../image/about/photo4.jpg",
  },
];

const About = () => {
  return (
    <div className="py">
      <div className="relative overflow-clip h-[36rem]  sm:h-[30rem] w-full bg-blue-200">
        <img
          className="w-full hidden md:inline opacity-50 object-cover"
          src={"../image/about/education.jpg"}
          alt="OUR STUDENTS"
        />
        <div className="md:absolute md:top-1/3 sm:top-0 w-full py-16 bg-primary opacity-70">
          <div className="grid grid-cols-1 lg:grid-cols-3 px-4 sm:px-20">
            <div>
              <img
                className="w-96 mx-auto"
                src={"../image/about/clipart.png"}
                alt="clipart"
              />
            </div>
            <div className="col-span-2 text-center">
              <h1 className="text-2xl lg:text-5xl leading-snug font-bold pb-6 uppercase text-white">
                Albert Tutorial and Volunteerism Center
              </h1>
              <p className="mt-2 text-gray-300">
                Albert Tutorial and Volunteerism Center: Offering summer
                tutoring for grades 7-12 students in Adama Town. We also assist
                underprivileged students who lack resources for educational
                materials.
                <span className="font-bold block py-4">
                  Empowering education for all.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <h3 className="text-center text-3xl font-bold">Know More About Us</h3>

        <div>
          <h3 className="text-xl py-6 text-gray-700 font-semibold text-center">
            OUR STORY
          </h3>
          <div className="grid grid-cols-1 gap-8 gap-y-8 lg:grid-cols-4 lg:px-24 ">
            {data.map((item, index) => {
              if (index % 2 === 0) {
                return (
                  <AboutCardLeft
                    key={index}
                    title={item.title}
                    year={item.year}
                    description={item.description}
                    image={item.image}
                  />
                );
              } else {
                return (
                  <AboutCardRight
                    key={index}
                    title={item.title}
                    year={item.year}
                    description={item.description}
                    image={item.image}
                  />
                );
              }
            })}
          </div>
        </div>
        <OurMission />
        <OurGallery />
        <div className="bg-primary text-white mb-16 uppercase px-6 py-20 sm:p-20 text-center">
          <h1 className="text-3xl font-semibold ">Be Part Of Our Story!</h1>{" "}
          <div className="pt-12">
            <Link
              className="bg-white text-primary rounded font-bold px-10 py-4"
              to="/register"
            >
              Join our community
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
