import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://backend.alberttutorial.com/api/members`,
    tagTypes: ["Members"],
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", "Bearer " + token);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getMembers: builder.query({
      query: (params) => ({
        url: "/",
        params,
      }),
      providesTags: ["Members"],
    }),
    addMember: builder.mutation({
      query: (member) => ({
        url: "/",
        method: "POST",
        body: member,
      }),
      invalidatesTags: ["Members"],
    }),
    updateMember: builder.mutation({
      query: ({ id, member }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: member,
      }),
      invalidatesTags: ["Members"],
    }),
    getOneMember: builder.query({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Members"],
    }),
    deleteMember: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Members"],
    }),
  }),
});

export const {
  useGetMembersQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useGetOneMemberQuery
} = memberApi;
