import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdOutlineCancel } from "react-icons/md";
import UserCard from "./UserCard";

export default function NoteModal({ setOpenModal, array = [], title="", note = "" }) {

    console.log(array,"the array to modal");
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black opacity-30 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-gray-300 border border-gray-100 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-3xl max-h-[70vh] overflow-scroll">
                <button
                  type="button"
                  className="absolute right-2 top-3 text-2xl"
                  onClick={() => setOpenModal(false)}
                  ref={cancelButtonRef}
                >
                  <MdOutlineCancel />
                </button>
                <div className="bg-gray-100 border border-gray-100 px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="pb-3 border-b border-gray-400">
                    <h1 className="text-xl font-semibold">{title}</h1>
                  </div>
                  {array.map((item, index) => {
                    return (
                      <div key={index} >
                         <UserCard createdBy={item} />
                      </div>
                    );
                  })}
                  <p className="text-gray-700">{note}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
