import { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { CgPlayListRemove } from "react-icons/cg";
import { RiMenu3Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import Modal from "../common/Modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Donate from "../common/DonateButton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const NavBar = () => {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);
  const { userName } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });
  const handleLogout = () => {
    dispatch(logout());
    setShowModal(false);
  };

  const navItems = [
    { name: "Home", to: "/" },
    { name: "About Us", to: "/about" },
    { name: "Posts", to: "/posts" },
    { name: "Resources", to: "/resources" },
    { name: "Our Team", to: "/our-team" },

    { name: "Contact", to: "/contact" },
  ];
  return (
    <div
      className={`fixed w-full transition-transform duration-700 z-50 bg-white text-[15px]  ${
        visible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      {" "}
      {open && (
        <Elements stripe={stripePromise}>
          <Donate open={open} setOpen={setOpen} />
        </Elements>
      )}
      <div className="flex justify-between text-tertiary-text shadow items-center p-2 py-4 md:py-5 md:px-6">
        <div>
          <NavLink className="py-5" to={"/"}>
            <img
              className="md:h-10 h-8"
              src="../../image/albert-logo.png"
              alt="Albert Logo"
            />
          </NavLink>
        </div>
        <div className="space-x-12 hidden md:flex items-center font-semibold pr-10">
          {navItems.map((navItem, index) => (
            <NavLink
              key={index}
              className={({ isActive }) => (isActive ? "text-primary" : "")}
              to={navItem.to}
            >
              {navItem.name}
            </NavLink>
          ))}
        </div>

        {userName ? (
          <div className="hidden md:flex space-x-3 items-center">
            <p className="uppercase text-sm">{userName}</p>

            <button
              className="md:inline-flex border font-semibold text-gray-700 hover:bg-gray-300 transition-all duration-500 bg-gray-200 px-3 py-1 mx-2 rounded"
              onClick={() => setShowModal(true)}
            >
              Logout
            </button>
            {showModal && (
              <Modal
                setOpenModal={setShowModal}
                handleFunction={handleLogout}
                data={{
                  title: "Want logout ?",
                  buttonText: "Logout",
                  description: "Are you sure, do you want to logout?",
                }}
              />
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <Link
              className="border font-semibold text-gray-700 hover:bg-gray-300 transition-all duration-500 bg-gray-200 px-3 py-1 mx-2 rounded"
              to="/register"
            >
              Sign up
            </Link>
            <Link
              className="border text-gray-50 font-semibold bg-primary hover:bg-blue-900 px-4 py-1 mx-2 rounded"
              to="/login"
            >
              Login
            </Link>

            {/* <div className="relative">
              <button
                onClick={() => setOpen(true)}
                className="border text-gray-50 font-semibold bg-primary hover:bg-blue-900 px-4 py-1 mx-2 rounded"
              >
                Donate
              </button>{" "}
              <span class="animate-ping h-6 w-6  -top-2 border-2 -right-0 absolute inline-flex rounded-full bg-primary opacity-75"></span>
              <span class="absolute inline-flex -top-1 border-2 right-1 rounded-full h-4 w-4 bg-primary"></span>
            </div> */}
          </div>
        )}
        <button
          className="md:hidden inline-flex  text-4xl "
          onClick={() => setMenu(!menu)}
        >
          {menu ? (
            <CgPlayListRemove />
          ) : (
            <>
              <RiMenu3Fill />
            </>
          )}
        </button>
      </div>
      <div
        className={`${
          menu
            ? "font-semibold translate-x-0 bg-gray-200 shadow"
            : "translate-x-[45rem]"
        } z-30 absolute flex flex-col  md:hidden duration-500 ease-out transition-all `}
        ref={menuRef}
      >
        <div className=" w-screen text-center p-5 flex flex-col space-y-3">
          {navItems.map((navItem, index) => (
            <NavLink
              onClick={() => setMenu(false)}
              key={index}
              className={({ isActive }) => (isActive ? "text-primary" : "")}
              to={navItem.to}
            >
              {navItem.name}
            </NavLink>
          ))}
          {userName && (
            <div className="flex flex-row space-x-4 bg-gray-300 rounded  py-4 justify-between px-10 items-center">
              <div className="flex items-center space-x-2">
                <p className="uppercase text-sm">{userName}</p>
              </div>

              <button
                className="md:inline-flex border font-semibold text-gray-700 hover:bg-gray-300 transition-all duration-500 bg-gray-200 px-3 py-1 mx-2 rounded"
                onClick={() => setShowModal(true)}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
