import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAddReplyMutation } from "../../../services/discussionApi";

const ReplyBox = ({ discussionId }) => {
  const [content, setContent] = useState("");
  const [addReply, { isLoading: isCommenting }] = useAddReplyMutation();

  const handleChange = (e) => {
    setContent(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight - 20}px`;
  };

  const handleSubmit = async (e) => {
    const response = await addReply({ discussionId, content });
    console.log(response);
    if (response.error) {
      toast.error(response.error.data.message || "Something went wrong!");
      return;
    }
    toast.success("Reply sumbitted successfully! ");
    setContent("");
  };

  return (
    <div>
      <form className=" w-5/6 ml-auto pl-4">
        <div className="relative mb-2 px-8">
          <textarea
            value={content}
            onChange={handleChange}
            placeholder="Enter your reply..."
            className="border h-11 focus:border-gray-300 outline-none w-full rounded px-4 py-2 resize-none"
          />
          <button
            type="button"
            onClick={handleSubmit}
            className="absolute right-8 bottom-2 rounded-lg bg-gray-500 text-white p-1 "
          >
            {isCommenting ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <RiSendPlaneFill />
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ReplyBox;
