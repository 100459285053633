import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useGetMembersQuery } from "../services/memberApi";
import TeamCard from "../components/pages/ourTeams/TeamCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const OurTeams = () => {
  const { userRole } = useSelector((state) => state.auth);
  const { data: teams, isLoading, error } = useGetMembersQuery();

  return (
    <div className="my-20 w-full flex flex-col justify-center items-center">
      <h1 className="text-2xl font-medium mt-10 mb-2 text-gray-700">
        OUR TEAM
      </h1>

      <h3 className="text- text-gray-500 mb-8">Meet the Team</h3>
      {userRole === "admin" && (
        <Link to={"/our-team/add-member"} className="text-blue-500">
          Add Team Member{" "}
        </Link>
      )}

      {isLoading ? (
        <div className="flex py-10 items-center text-gray-500 space-x-2 justify-center">
          <AiOutlineLoading3Quarters className="animate-spin" />{" "}
          <p>Loading...</p>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">Error while fetching!</div>
      ) : (
        <div className="grid gap-2 sm:gap-4 lg:gap-8 mx-4 sm:mx-28 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {teams.map((member, index) => {
            return <TeamCard key={index} member={member} />;
          })}
        </div>
      )}
    </div>
  );
};

export default OurTeams;
