const Pagination = ({ onPageChange, totalCount, currentPage }) => {
  const totalPages = Math.ceil(totalCount / 5);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex justify-center space-x-2">
        {pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`rounded-lg ${
              pageNumber === currentPage
                ? "bg-primary text-white"
                : "bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700"
            }`}
          >
            <button
              className="px-4 py-2 focus:outline-none"
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
