import React, { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { setCredentials } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const GoogleAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccess = async (credentialResponse) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend.alberttutorial.com/api/auth/google-auth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            credential: credentialResponse.credential,
            client_id: clientId,
          }),
        }
      );
      const user = await response.json();
      console.log(user, "loged in user");
      if (user.userId) {
        dispatch(setCredentials(user));

        toast.success("Logged in successfully!");
        setTimeout(() => {
          const { from } = location.state || { from: { pathname: "/" } };
          if (from === "/posts") {
            navigate("/posts");
          } else {
            navigate("/");
          }
        }, 1000);
      } else {
        toast.error("Login Failed");
      }
    } catch (error) {
      toast.error("Login Failed");
      console.error("Error:", error);
    }
    setIsLoading(false);
  };
  const handleError = () => {
    toast.error("Login Failed");
    console.log("Login Failed");
  };

  return (
    <div className="w-full overflow-x-clip">
      <GoogleOAuthProvider clientId={clientId}>
        {isLoading ? (
          <div className="border rounded bg-white text-center text-gray-600 py-2 text-xs">
            Signing in...
          </div>
        ) : (
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            useOneTap
            width={"400px"}
          />
        )}
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuth;
