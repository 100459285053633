import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const discussionApi = createApi({
  reducerPath: "discussionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://backend.alberttutorial.com/api/discussions`,
    tagTypes: ["Discussion"],
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", "Bearer " + token);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getDiscussions: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
      }),
      providesTags: ["Discussion"],
    }),

    likeDiscussion: builder.mutation({
      query: (id) => ({
        url: `${id}/like`,
        method: "POST",
      }),
      invalidatesTags: ["Discussion"],
    }),

    deleteDiscussion: builder.mutation({
      query: (discussionId) => ({
        url: `/${discussionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Discussion"],
    }),

    deleteReply: builder.mutation({
      query: ({discussionId,replyId}) => ({
        url: `/${discussionId}/replies/${replyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Discussion"],
    }),

    addDiscussion: builder.mutation({
      query: ({ content }) => ({
        url: "/",
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Discussion"],
    }),

    addReply: builder.mutation({
      query: ({ discussionId, content }) => ({
        url: `/${discussionId}/reply`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Discussion"],
    }),
  }),
});

export const {
  useGetDiscussionsQuery,
  useAddDiscussionMutation,
  useLikeDiscussionMutation,
  useDeleteDiscussionMutation,
  useAddReplyMutation,
  useDeleteReplyMutation
} = discussionApi;
