import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../common/Modal";
import dateFormatter from "../../../utils/dateFormatter";
import {
  useDeleteDiscussionMutation,
  useGetDiscussionsQuery,
  useLikeDiscussionMutation,
} from "../../../services/discussionApi";
import DiscussionBox from "./DiscussionBox";
import ReplySection from "./ReplySection";
import UserCard from "../../common/UserCard";

const Discussion = () => {
  const [showModal, setShowModal] = useState(false);
  const { data: discussions, isLoading, isError } = useGetDiscussionsQuery();
  const [likeDiscussion] = useLikeDiscussionMutation();
  const { userId, userToken } = useSelector((state) => state.auth);
  const [likeLoading, setLikeLoading] = useState(null);
  const [deleteDiscussion, { isLoading: isDeleting }] =
    useDeleteDiscussionMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showReplies, setShowReplies] = useState({});

  const handleLike = async (id) => {
    try {
      await likeDiscussion(id);
    } catch (error) {
      toast.error("Something went wrong while liking the discussion!");
    }
  };

  const toggleReplies = (discussionId) => {
    setShowReplies((prevState) => ({
      ...prevState,
      [discussionId]: !prevState[discussionId],
    }));
  };

  return (
    <div className="md:px-20">
      <div className="flex space-x-2 mt-24 items-center">
        <img
          className="w-36"
          src="../../image/contact/question.png"
          alt="quesion clipart"
        />
        <div>
          <h1 className="text-2xl font-semibold text-gray-700 uppercase">
            Ask question here
          </h1>
          <p className="text-sm text-gray-500">Chat here</p>
        </div>
      </div>

      {userToken ? (
        <div>
          <DiscussionBox />
        </div>
      ) : (
        <button
          className="hover:underline sm:px-10 text-primary mx-auto py-2 mt-4 px-4"
          onClick={() =>
            navigate("/login", {
              state: { from: location.pathname },
            })
          }
        >
          Login to ask question
        </button>
      )}
      {isLoading ? (
        <div className="w-full flex flex-col items-center my-auto text-gray-600">
          <AiOutlineLoading3Quarters className="animate-spin" />
        </div>
      ) : isError ? (
        <div className="w-full flex flex-col items-center my-auto text-gray-700">
          <p className="my-auto">Error occurred while fetching discussions.</p>
        </div>
      ) : (
        <div className="w-full ">
          {discussions.length === 0 ? (
            <div className="w-full flex justify-center my-2 text-gray-500">
              <p>No discussion yet!</p>
            </div>
          ) : (
            discussions.map((discussion, index) => {
              const verbalDate = dateFormatter(discussion.createdAt);
              const isLiked = discussion.likes.includes(userId);
              const isMyDiscussion = discussion.createdBy._id === userId;
              const showReplySection = showReplies[discussion._id];

              return (
                <div key={index}>
                  <div className="bg-gray-100 my-4 mx-8 rounded-lg p-4 py-2 pt-0">
                    <div className="flex items-end justify-between">
                      <UserCard createdBy={discussion.createdBy} />
                      <p className="py-5 uppercase text-xs text-gray-500">
                        {verbalDate}
                      </p>
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-700 my-2">{discussion.content}</p>
                    </div>
                    <div className="flex basis-1/3 items-center space-x-4">
                      <button
                        className="h-6 w-4"
                        onClick={async () => {
                          setLikeLoading(discussion._id);
                          if (!userToken) {
                            setShowModal(true);
                          } else {
                            await handleLike(discussion._id);
                          }
                          setLikeLoading(null);
                        }}
                      >
                        {likeLoading === discussion._id ? (
                          <AiOutlineLoading3Quarters className="animate-spin m-1" />
                        ) : isLiked ? (
                          <AiFillHeart className="text-red-500 text-xl" />
                        ) : (
                          <AiOutlineHeart className="text-xl text-gray-500" />
                        )}
                      </button>

                      {showModal && (
                        <Modal
                          setOpenModal={setShowModal}
                          handleFunction={() =>
                            navigate("/login", {
                              state: { from: location.pathname },
                            })
                          }
                          data={{
                            title: "Want to Login?",
                            buttonText: "Login",
                            description:
                              "You should log in before giving a like.",
                          }}
                        />
                      )}
                      <div className="flex flex-row w-full justify-between items-center">
                        <p className="text-gray-500 text-xs basis-1/3">
                          {discussion.likes.length}
                          {discussion.likes.length === 1 ? " Like" : " Likes"}
                        </p>

                        <button
                          className="basis-1/3 text-xs text-gray-500 hover:text-primary"
                          onClick={() => toggleReplies(discussion._id)}
                        >
                          {showReplySection
                            ? "Close"
                            : `${discussion.replies?.length} Replies`}
                        </button>
                        <div className="basis-1/3 text-end">
                          {isMyDiscussion && (
                            <button
                              className="text-red-500 text-xs "
                              onClick={async () => {
                                await deleteDiscussion(discussion._id);
                              }}
                            >
                              {isDeleting ? (
                                <AiOutlineLoading3Quarters className="animate-spin" />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showReplySection && <ReplySection discussion={discussion} />}
                </div>
              );
            })
          )}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Discussion;
