import Navbar from "./components/layout/NavBar";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Landing from "./pages/Landing";
import Footer from "./components/layout/Footer";
import About from "./pages/About";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Posts from "./pages/Posts";
import { useEffect, useRef } from "react";
import AddPost from "./pages/AddPost";
import SinglePost from "./pages/SinglePost";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import Resource from "./pages/Resources";
import AddResource from "./pages/AddResource";
import OurTeams from "./pages/OurTeams";
import AddTeamMember from "./pages/AddTeamMember";
import EditTeamMember from "./pages/EditTeamMember";

const ScrollToTop = () => {
  const prevLocation = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = location.pathname;
    }
  }, [location]);

  return null;
};

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/our-team" element={<OurTeams />} />
          <Route path="/our-team/add-member" element={<AddTeamMember />} />
          <Route path="/our-team/edit/:id" element={<EditTeamMember />} />
          <Route path="/posts/add-post" element={<AddPost />} />
          <Route path="/posts/single-post/:id" element={<SinglePost />} />
          <Route path="/resources" element={<Resource />} />
          <Route path="/resources/add-resource" element={<AddResource />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
