import Grid from "../components/pages/posts/Grid";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetActivitiesQuery } from "../services/activityApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PostIntro from "../components/pages/posts/PostIntro";
import PostDetail from "../components/pages/posts/PostDetail";
import PostInteraction from "../components/pages/posts/PostInteraction";

const Post = () => {
  const { userRole } = useSelector((state) => state.auth);

  const { data: activities, isLoading, error } = useGetActivitiesQuery();

  return (
    <section className="py-10 overflow-x-hidden">
      <PostIntro />
      <div className="my-6">
        {userRole === "staff" && (
          <Link
            to={"/posts/add-post"}
            className="bg-primary text-white py-3 px-5 hover:bg-blue-900 border-gray-500 font-semibold mt-10 duration-100"
          >
            Create new post
          </Link>
        )}
      </div>

      {isLoading ? (
        <div className="flex py-10 items-center space-x-2 justify-center">
          <AiOutlineLoading3Quarters className="animate-spin" />{" "}
          <p>Loading...</p>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">Error while fetching</div>
      ) : activities.length === 0 ? <div className="flex text-gray-600 justify-center"> <p>No posts yet!</p> </div>:(
        activities.map((activity) => {
          return (
            <div
              key={activity._id}
              className="col-span-2 my-10 mx-4 lg:w-3/5 sm:mx-auto shadow bg-gray-50 rounded pb-6"
            >
              <Grid images={activity.images} />
              <PostDetail activity={activity} />
              <PostInteraction activity={activity} />
            </div>
          );
        })
      )}
      <ToastContainer />
    </section>
  );
};

export default Post;
