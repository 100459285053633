import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import SocialMediaContact from "./SocialMediaSection";

const EmailSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const sendEmail = (e) => {
    setIsloading(true);
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };

    emailjs
      .send(
        "service_aqv4jcf",
        "template_zsplwwt",
        templateParams,
        "EhqCDiOFiJ2F1LM1m"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsloading(false);
          toast.success("Email sent successfully!");
          setName("");
          setEmail("");
          setMessage("");
          setSubject("");
        },
        (error) => {
          console.log(error.text);
          setIsloading(false);
          toast.error("Something went wrong while sending the email!");
        }
      );
  };

  return (
    <div className="pt-16">
      <div>
        <h1 className="text-2xl font-semibold text-gray-700 uppercase">
          Contact us
        </h1>
         <SocialMediaContact />
        
      </div>
      <form className="w-full -red-400 mx-aut" onSubmit={sendEmail}>
        <div className="flex space-x-4">
          <div className="mb-4 basis-1/2">
            <label
              htmlFor="name"
              className="block text-sm text-gray-600 font-medium"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-200 rounded-md outline-none focus:border-gray-300 text-gray-700"
              required
            />
          </div>
          <div className="mb-4 basis-1/2">
            <label
              htmlFor="email"
              className="block text-sm text-gray-600 font-medium"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-200 rounded-md outline-none focus:border-gray-300 text-gray-700"
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="subject"
            className="block text-sm text-gray-600 font-medium"
          >
            Subject
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full px-3 py-2 border border-gray-200 rounded-md outline-none focus:border-gray-300 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-sm text-gray-600 font-medium"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full px-3 py-2 border border-gray-200 rounded-md outline-none focus:border-gray-300 text-gray-700"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            className="py-2 px-4 bg-primary hover:bg-blue-600 text-white rounded"
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EmailSection;
