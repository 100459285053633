const dateFormatter = (incomingDate) => {
    const dateFormat = new Date(incomingDate);

    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const verbalDate = dateFormat.toLocaleDateString("en-US", options);
    return verbalDate;
}
 
export default dateFormatter;