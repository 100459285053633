import { BsArrowReturnRight } from "react-icons/bs";

import UserCard from "../../common/UserCard";
import ReplyBox from "./ReplyBox";
import { useDeleteReplyMutation } from "../../../services/discussionApi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ReplySection = ({ discussion }) => {
  const { userId, userToken } = useSelector((state) => state.auth);
  const [deleteReply, { isLoading: isReplyDeleting }] =
    useDeleteReplyMutation();
  return (
    <div className="py-2 pt-0 mb-10 max-h-72 overflow-y-scroll">
      {userToken ? (
        <ReplyBox discussionId={discussion._id} />
      ) : (
        <div className="w-full flex justify-center my-2 text-gray-500">
            
          <Link className="text-sm hover:underline hover:text-primary" to="/login">login to reply</Link>
        </div>
      )}
      <div className="flex flex-col-reverse items-end  ">
        {discussion.replies.length === 0 ? (
          <div className="w-full flex justify-center text-xs text-gray-500">
            <p>No replies yet!</p>
          </div>
        ) : (
          discussion.replies.map((reply, index) => {
            const isMyReply = reply.createdBy._id === userId;
            return (
              <div
                key={index}
                className="flex justify-between w-5/6 items-center"
              >
                <div className="text-gray-400">
                  <BsArrowReturnRight />
                </div>

                <div className="basis-11/12 bg-gray-100 my-1 text-sm mx-8 rounded-lg px-4 pb-2">
                  <div>
                    <UserCard createdBy={reply.createdBy} />
                  </div>
                  <p className="text-gray-700">{reply.content}</p>
                  {isMyReply && (
                    <button
                      className="text-red-500 text-xs text-end ml-auto w-full"
                      onClick={async () => {
                        await deleteReply({
                          discussionId: discussion._id,
                          replyId: reply._id,
                        });
                      }}
                    >
                      {isReplyDeleting ? "Deleting..." : "Delete"}
                    </button>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ReplySection;
