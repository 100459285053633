const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  userId: null,
  userToken: null,
  userRole: null,
  userName: null,
  userAvatar : null
};

const persistedState = JSON.parse(localStorage.getItem("auth"));

const authSlice = createSlice({
  name: "auth",
  initialState: persistedState || initialState,
  reducers: {
    setCredentials: (state,{ payload: { userName, userRole, userToken, userId,userAvatar } }) => {
      state.userId = userId;
      state.userToken = userToken;
      state.userRole = userRole;
      state.userName = userName;
      state.userAvatar = userAvatar;

      localStorage.setItem("auth", JSON.stringify(state));
    },
    logout: (state) => {
      state.userId = null;
      state.userToken = null;
      state.userRole = null;
      state.userName = null;

      localStorage.removeItem("auth");
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

