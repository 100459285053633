import { useState } from "react";
import {
  RiFacebookCircleLine,
  RiLinkedinBoxFill,
  RiTelegramLine,
} from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "../../common/Modal";
import { useDeleteMemberMutation } from "../../../services/memberApi";

const TeamCard = ({ member }) => {
  const { userRole } = useSelector((state) => state.auth);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteMember, { isLoading: isDeleting }] = useDeleteMemberMutation();
  const handleDelete = async () => {
    try {
      const response = await deleteMember(deleteId);
      if (response?.error?.data) {
        toast.error(response.error.data?.message);
        return;
      } else if (response?.error) {
        toast.error("Something went wrong while deleting the resource!");
        return;
      }
      setShowModalDelete(false);
      toast.success("Post deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong while deleting the resource!");
    }
  };

  return (
    <div className="flex flex-col items-center bg-gray-50">
      <div className="flex relative items-center px-8 py-4 justify-between bg-gray-100 w-full ">
        <img
          className="w-32 h-32 object-cover rounded-full mr-8"
          src={member.image}
          alt={member.name}
        />
        {userRole === "admin" && (
          <div className="flex text-start absolute top-0 left-0 flex-col text-xs space-y-4">
            <button
              onClick={() => {
                setDeleteId(member._id);
                setShowModalDelete(true);
              }}
              className="text-red-500"
            >
              Delete
            </button>
            {userRole === "admin" && (
              <Link to={`/our-team/edit/${member._id}`} className="text-blue-500">
                Edit
              </Link>
            )}
          </div>
        )}
        {showModalDelete && (
          <Modal
            setOpenModal={setShowModalDelete}
            handleFunction={() => {
              handleDelete();
            }}
            data={{
              title: "Want Delete ?",
              buttonText: isDeleting ? "Deleting..." : "Delete",
              description: "Are you sure to resource this post ?",
            }}
            color="bg-red-500"
          />
        )}
        <div className="mx-auto flex flex-col text-gray-600 text-xl p-1">
          <Link
            to={`${member.contacts?.telegram}`}
            target="_blank"
            rel="noopener noreferrer"
            className={ `${!member?.contacts?.telegram ? "hidden":""} hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group`}
          >
            <RiTelegramLine />
          </Link>

          <Link 
             to={`${member?.contacts?.linkedIn}`}
            target="_blank"
            rel="noopener noreferrer"
            className={ `${!member?.contacts?.linkedIn ? "hidden":""} hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 group`}
          >
            <RiLinkedinBoxFill />
          </Link>
          <Link
             to={`${member?.contacts?.facebook}`}
            target="_blank"
            rel="noopener noreferrer"
            className={ `${!member?.contacts?.facebook ? "hidden":""} group hover:bg-gray-200 hover:text-primary transition-all duration-700 rounded-full p-2 `}
          >
            <RiFacebookCircleLine />
          </Link>
        </div>
      </div>
      <div className="w-full p-4 pr-2">
        <h6 className="text- font-medium">{member.name}</h6>
        <p className="text-gray-600 mb-3 text-sm">{member.position}</p>

        <p className="text-sm text-gray-500 max-h-36 pr-2 overflow-y-scroll">
          {member.bio}
        </p>
      </div>

      <ToastContainer />
    </div>
  );
};

export default TeamCard;
