import { configureStore } from "@reduxjs/toolkit";
import { activityApi } from "../activityApi";
import { authApi } from "../authApi";
import authSlice from "../../features/auth/authSlice";
import { discussionApi } from "../discussionApi";
import { resourceApi } from "../resourceApi";
import { memberApi } from "../memberApi";

const store = configureStore({
  reducer: {
    auth: authSlice,
    [activityApi.reducerPath]: activityApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [discussionApi.reducerPath]:discussionApi.reducer,
    [resourceApi.reducerPath]:resourceApi.reducer,
    [memberApi.reducerPath]:memberApi.reducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(activityApi.middleware)
      .concat(authApi.middleware)
      .concat(discussionApi.middleware)
      .concat(resourceApi.middleware)
      .concat(memberApi.middleware)
});

export default store;
