import React, { useEffect } from "react";

const Sidebar = ({ onSearch, onFilter }) => {
  const [selectedSubject, setSelectedSubject] = React.useState([]);
  const [selectedGrade, setSeletedGrade] = React.useState([]);
  const [selectedSortBy, setSelectedSortBy] = React.useState("Most Recent");

  const subjects = [
    "Aptitude",
    "Biology",
    "Chemistry",
    "Economics",
    "Geography",
    "Mathematics",
    "English",
    "Physics",
    "General Knowledge",
  ];
  const grades = [
    "Grade 7 & 8",
    "Grade 9 & 10",
    "Grade 11 & 12",
    "Freshman",
    "General",
  ];
  const sortBys = [
    "Most Recent",
    "Oldest",
    "Ascending Title ",
    "Descending Title",
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value;

    onSearch(searchTerm);
  };

  useEffect(() => {
    onFilter({
      subjects: selectedSubject,
      grades: selectedGrade,
      sortBy: selectedSortBy,
    });
  }, [selectedGrade, selectedSortBy, selectedSubject]);

  const handleFilter = (event) => {
    const { name, value } = event.target;
    if (name === "subject") {
      if (selectedSubject.includes(value)) {
        const filteredSelectedSubject = selectedSubject.filter(
          (subject) => subject !== value
        );
        setSelectedSubject(filteredSelectedSubject);
      } else {
        setSelectedSubject([...selectedSubject, value]);
      }
    }
    if (name === "grade") {
      if (selectedGrade.includes(value)) {
        const filteredSelectedGrade = selectedGrade.filter(
          (grade) => grade !== value
        );
        setSeletedGrade(filteredSelectedGrade);
      } else {
        setSeletedGrade([...selectedGrade, value]);
      }
    }

    if (name === "sortBy") {
      setSelectedSortBy(value);
    }
  };

  return (
    <div className="flex flex-col pt-8">
      <h3 className="font-medium mb-6 ps-2">Filters</h3>
      <input
        type="text"
        placeholder="Search"
        onChange={handleSearch}
        className="px-4 py-2 outline-none border focus:border-gray-300 mb-6 border-gray-200 rounded-full"
      />
      <div className="p-2 mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1 px-1">
          Subject
        </label>
        <div className="flex flex-wrap gap-3 mt-1 text-xs">
          <button
            name="all"
            value="All"
            onClick={() => setSelectedSubject([])}
            className={`px-3 py-1 rounded-full text-gray-700 border border-neutral-200 ${
              selectedSubject.length === 0
                ? "bg-gray-200 border font-semibold text-black"
                : ""
            }`}
          >
            All
          </button>

          {subjects.map((subject, index) => {
            return (
              <button
                key={index}
                name="subject"
                value={subject}
                onClick={handleFilter}
                className={`px-3 py-1 rounded-full text-gray-700 border border-neutral-200 ${
                  selectedSubject.includes(subject)
                    ? "bg-gray-200 border font-semibold text-black"
                    : ""
                }`}
              >
                {subject}
              </button>
            );
          })}
        </div>
      </div>
      <div className="p-2 mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1 px-1">
          Grade
        </label>
        <div className="flex flex-wrap gap-3 mt-1 text-xs">
          <button
            name="all"
            value="All"
            onClick={() => setSeletedGrade([])}
            className={`px-3 py-1 rounded-full text-gray-700 border border-neutral-200 ${
              selectedGrade.length === 0
                ? "bg-gray-200 border font-semibold text-black"
                : ""
            }`}
          >
            All
          </button>
          {grades.map((grade, index) => {
            return (
              <button
                key={index}
                name="grade"
                value={grade}
                onClick={handleFilter}
                className={`px-3 py-1 rounded-full text-gray-700 border border-neutral-200 ${
                  selectedGrade.includes(grade)
                    ? "bg-gray-200 border font-semibold text-black"
                    : ""
                }`}
              >
                {grade}
              </button>
            );
          })}
        </div>
      </div>
      <div className="p-2 mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1 px-1">
          Sort By
        </label>
        <div className="flex flex-wrap gap-3 mt-1 text-xs">
          {sortBys.map((sortBy, index) => {
            return (
              <button
                key={index}
                name="sortBy"
                value={sortBy}
                onClick={handleFilter}
                className={`px-3 py-1 rounded-full text-gray-700 border border-neutral-200 ${
                  selectedSortBy === sortBy
                    ? "bg-gray-200 border font-semibold text-black"
                    : ""
                }`}
              >
                {sortBy}
              </button>
            );
          })}
        </div>
      </div>
      {/* Add more filter options here */}
    </div>
  );
};

export default Sidebar;
