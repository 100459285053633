import { AiFillStar, AiOutlineLoading3Quarters } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineBookOpen } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dateFormatter from "../../../utils/dateFormatter";
import UserCard from "../../common/UserCard";
import { useSelector } from "react-redux";
import Modal from "../../common/Modal";
import { RiDeleteBin4Line } from "react-icons/ri";
import { useState } from "react";
import {
  useDeleteResourceMutation,
  useLikeResourceMutation,
} from "../../../services/resourceApi";

const ResourceCard = ({ resource }) => {
  const { userToken, userId } = useSelector((state) => state.auth);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [likeLoading, setLikeLoading] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [likeResource] = useLikeResourceMutation();
  const [deleteResource, { isLoading: isDeleting }] =
    useDeleteResourceMutation();

  const handleLike = async (id) => {
    try {
      await likeResource(id);
    } catch (error) {
      const message = error?.data?.message;
      toast.error(message || "Something went wrong while liking the resource!");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteResource(deleteId);
      if (response?.error) {
        toast.error(response.error?.message);
        return;
      } else if (response?.error) {
        toast.error("Something went wrong while deleting the resource!");
        return;
      }
      setShowModalDelete(false);
      toast.success("Resource deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong while deleting the resource!");
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const verbalDate = dateFormatter(resource.createdAt);
  const isMyResource = resource.createdBy._id === userId;
  const isLiked = resource.likes.map((like) => like._id).includes(userId);
  console.log(`../../image/resources/${resource.subject}.jpg`);

  return (
    <div className="col-span-7 md:max-h-72 flex flex-col md:flex-row rounded-md shadow border-t border-gray-100 gray-200">
      <img
        className="object-contain bg-gray-200 md:object-cover h-48 md:h-auto md:w-52"
        src={`../../image/resources/${resource.subject}.jpg`}
        alt={`${resource.subject} book`}
      />
      <div className="flex w-full justify-between">
        <div className="p-4 md:p-6 w-full">
          <div className="flex justify-between items-center">
            <h2 className="text-lg md:mb-2 mb-4 font-medium uppercase text-gray-600">
              {resource.title}
            </h2>
            <div className="flex space-x-2 items-center">
              <button
                className=""
                onClick={async () => {
                  setLikeLoading(resource._id);
                  if (!userToken) {
                    setShowModal(true);
                  } else {
                    await handleLike(resource._id);
                  }
                  setLikeLoading(null);
                }}
              >
                {likeLoading === resource._id ? (
                  <AiOutlineLoading3Quarters className="animate-spin m-1" />
                ) : isLiked ? (
                  <AiFillStar
                    className="border rounded border-yellow-500 text-yellow-500 p-1"
                    size={24}
                  />
                ) : (
                  <AiFillStar
                    className="border rounded p-1 text-gray-400  "
                    size={24}
                  />
                )}
              </button>

              <p className="text-xs text-gray-500">
                {resource.likes.length}{" "}
                {resource.likes.length === 1 ? "star" : "stars"}
              </p>
              {isMyResource && (
                <button
                  onClick={() => {
                    setDeleteId(resource._id);
                    setShowModalDelete(true);
                  }}
                  className="flex items-center space-x-2 ml-auto "
                >
                  <RiDeleteBin4Line className="text-xl text-red-500" />
                  <p className="text-sm hidden md:block text-red-500">Delete</p>
                </button>
              )}
              {showModalDelete && (
                <Modal
                  setOpenModal={setShowModalDelete}
                  handleFunction={() => {
                    handleDelete();
                  }}
                  data={{
                    title: "Want Delete ?",
                    buttonText: isDeleting ? "Deleting..." : "Delete",
                    description:
                      "Are you sure you want to delete this resource?",
                  }}
                  color="bg-red-500"
                />
              )}

              {showModal && (
                <Modal
                  setOpenModal={setShowModal}
                  handleFunction={() =>
                    navigate("/login", {
                      state: { from: location.pathname },
                    })
                  }
                  data={{
                    title: "Want Login ?",
                    buttonText: "Login",
                    description: "You should log in before giving a like",
                  }}
                />
              )}
            </div>
          </div>
          <div className=" text-gray-500 flex mb-2 text-xs">
            <div className="flex pr-2 bg-gray-10 space-x-1">
              <h3> {resource.subject}</h3>
              <h2>for</h2>
              <h3> {resource.grade}</h3>
            </div>
          </div>
          <div className="flex flex-wrap items-center border-b pb-3 border-gray-200 text-gray-500 space-x-3 text-xs">
            <h2>Topics</h2>
            {resource.topics.map((topic, index) => {
              return (
                <h3 key={index} className="p-1 px-3 bg-gray-100">
                  {topic}
                </h3>
              );
            })}{" "}
          </div>

          <div className="md:max-h-16 my-2 mt-3 md:overflow-y-scroll">
            <p className="text-gray-500 text-sm over">{resource.description}</p>
          </div>

          <div className="flex flex-col md:flex-row justify-between pt-auto text-xs text-gray-600">
            <div className="border-l-4 my-5 px-3 flex space-x-4 items-center ">
              <UserCard createdBy={resource.createdBy} />
            </div>
            <div className=" md:my-5 md:px-3 flex flex-row-reverse md:flex-row justify-between md:space-x-4 items-center text-gray-400">
              <p>{verbalDate}</p>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={resource.file}
                className="bg-primary flex justify-between space-x-2 items-center text-white rounded p-1 px-3"
              >
                <HiOutlineBookOpen size={17} />

                <span>Open</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResourceCard;
