import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAddDiscussionMutation } from "../../../services/discussionApi";

const DiscussionBox = () => {
  const [content, setContent] = useState("");
  const [addDiscussion, { isLoading: isDiscussioning }] =
    useAddDiscussionMutation();

  const handleChange = (e) => {
    setContent(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleSubmit = async () => {
    const response = await addDiscussion({ content });
    if (response.error) {
      toast.error(response?.error?.data?.message || "Something went wrong!");
      return;
    }
    toast.success("The discussion has been submitted successfully! ");
    setContent("");
  };

  return (
    <div>
      <form className="mt-4">
        <div className="relative mb-4 px-8">
          <textarea
            value={content}
            onChange={handleChange}
            placeholder="Enter your question..."
            className="border  focus:border-gray-300 outline-none w-full rounded px-4 py-2 resize-none"
          />
          <button
            type="button"
            onClick={handleSubmit}
            className="absolute right-8 bottom-2 rounded-lg bg-gray-500 text-white  p-1 "
          >
            {isDiscussioning ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <RiSendPlaneFill />
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default DiscussionBox;
