import ModalImage from "react-modal-image";
const data = [
  {
    year: "2024 G.C",
    figures: [
      {
        url: "../image/about/2024/1.jpg",
        caption: "After the 2024 Closing Ceremony",
      },
      {
        url: "../image/about/2024/2.jpg",
        caption: "Experience Sharing with University Students",
      },
      {
        url: "../image/about/2024/3.jpg",
        caption: "Aymen with his students",
      },
      {
        url: "../image/about/2024/4.jpg",
        caption: "Our outstanding teachers.",
      },
    ],
  },
  {
    year: "2022 G.C",
    figures: [
      {
        url: "../image/about/2022/1.jpg",
        caption: "Grade 9 students with Dagnachew.",
      },
      {
        url: "../image/about/2022/2.jpg",
        caption: "Grade 11 students.",
      },
      {
        url: "../image/about/2022/3.jpg",
        caption: "Grade 12 students and 2022 UEE takers.",
      },
      {
        url: "../image/about/2022/4.jpg",
        caption: "Grade 12 students and 2022 UEE takers.",
      },
      {
        url: "../image/about/2022/5.jpg",
        caption: "Grade 10 students.",
      },
      {
        url: "../image/about/2022/6.jpg",
        caption: "Grade 8 students.",
      },
      {
        url: "../image/about/2022/7.jpg",
        caption: "Grade 9 students.",
      },
      {
        url: "../image/about/2022/8.jpg",
        caption: "Our dedicated teachers.",
      },
    ],
  },
  {
    year: "2019 G.C",
    figures: [
      {
        url: "../image/about/2019/1.jpg",
        caption: "Grade 10 students.",
      },
      {
        url: "../image/about/2019/2.jpg",
        caption: "Grade 9 students.",
      },
      {
        url: "../image/about/2019/3.jpg",
        caption: "Grade 9 students with Abdullah",
      },
      {
        url: "../image/about/2019/4.jpg",
        caption: "Grade 10 students.",
      },
      {
        url: "../image/about/2019/5.jpg",
        caption: "Our amazing teacher team.",
      },
      {
        url: "../image/about/2019/6.jpg",
        caption: "Our students during Green legacy",
      },
      {
        url: "../image/about/2019/7.jpg",
        caption: "Supporting students with materials.",
      },
      {
        url: "../image/about/2019/8.jpg",
        caption: "Supporting students with materials.",
      },
    ],
  },
  {
    year: "2018 G.C",
    figures: [
      {
        url: "../image/about/2018/1.jpg",
        caption: "Our students in 2018",
      },
      {
        url: "../image/about/2018/2.jpg",
        caption: "Our students with mikyas",
      },
      {
        url: "../image/about/2018/3.jpg",
        caption: "Our students in 2018",
      },
      {
        url: "../image/about/2018/4.jpg",
        caption: "Our students in 2018",
      },
    ],
  },

  {
    year: "2017 G.C",
    figures: [
      {
        url: "../image/about/2017/1.jpg",
        caption: "Our students with Abraham",
      },
      {
        url: "../image/about/2017/2.jpg",
        caption: "Our students with Mikiyas",
      },
      {
        url: "../image/about/2017/3.jpg",
        caption: "Grade 10 students during the final exam",
      },
      {
        url: "../image/about/2017/4.jpg",
        caption: "Our students with Foli.",
      },
    ],
  },

  {
    year: "2015 G.C",
    figures: [
      {
        url: "../image/about/2015/1.jpg",
        caption: "Student during the exam with Natnael",
      },
      {
        url: "../image/about/2015/2.jpg",
        caption: "Students during the final exam",
      },
      {
        url: "../image/about/2015/3.jpg",
        caption: "Students during the final exam",
      },
      {
        url: "../image/about/2015/4.jpg",
        caption: "Biniyam with his students",
      },
      {
        url: "../image/about/2015/5.jpg",
        caption: "Students during exam",
      },
      {
        url: "../image/about/2015/6.jpg",
        caption: "Biniyam, Duguma and Abraham.",
      },
      {
        url: "../image/about/2015/7.jpg",
        caption: "Biniyam receiving an award",
      },
      {
        url: "../image/about/2015/8.jpg",
        caption: "Our students and teachers.",
      },
    ],
  },
];

const OurGallery = () => {
  return (
    <div>
      <h3 className="text-2xl my-10 mt-20 text-gray-700 font-semibold text-center">
        OUR GALLERY
      </h3>
      <p className="mx-10 md:mx-32 pb-14 text-gray-600 border-b border-gray-300">
        A visual showcase of the vibrant and diverse activities and events that
        have taken place at the Albert Tutorial and Volunteerism Center. Browse
        through our collection of captivating photographs and relive the
        memorable moments shared by our dedicated team, passionate volunteers,
        and the students we serve.
      </p>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-6 md:mx-20 gap-8 mb-20"
          >
            <h2 className="text-lg col-span-1 md:col-span-2 lg:col-span-4 mt-10 text-start text-gray-700 font-semibold uppercase">
              {item.year}
            </h2>
            {item.figures.map((image, index) => {
              return (
                <div
                  key={index}
                  className="transition-all hover:shadow-xl  duration-700"
                >
                  <ModalImage
                    className="h-64 w-full rounded-t  object-cover"
                    small={image.url}
                    large={image.url}
                    alt={image.caption}
                  />

                  <figcaption className="p-2 px-3 text-gray-600 text-sm bg-gray-200 border border-gray-300 ">
                    {image.caption}
                  </figcaption>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default OurGallery;
