import { useGetResourcesQuery } from "../services/resourceApi";
import Sidebar from "../components/pages/resources/SideBar";
import ResourceCard from "../components/pages/resources/ResourceCard";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Pagination from "../components/pages/resources/Pagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Resources = () => {
  const { userRole } = useSelector((state) => state.auth);
  const [params, setParams] = useState({});
  const { data: resources, isLoading, error } = useGetResourcesQuery(params);

  function toCamelCase(str) {
    let newStr = str.split(" ").join("");
    newStr = newStr[0].toLowerCase() + newStr.substring(1);
    return newStr;
  }

  const onFilter = (filterParam) => {
    const subject = filterParam?.subjects.join(",");
    const grade = filterParam?.grades.join(",");
    const sortBy = toCamelCase(filterParam?.sortBy); // to change it to camel

    setParams({ subject, grade, sortBy, page: 1, limit: 5 });
  };

  const onPageChange = (page) => {
    setParams({ ...params, page });
  };

  const onSearch = (search) => {
    setParams({ ...params, search });
  };

  return (
    <div className="lg:grid lg:grid-cols-11 flex flex-col-reverse bg-gray-50 min-h-screen ">
      <div className="col-span-8 mx-4 my-32 md:ml-16 md:mr-12 ">
        {userRole === "staff" && (
          <div className="mb-4">
            <Link
              to={"/resources/add-resource"}
              className="bg-primary text-white p-2 px-4 rounded"
            >
              Add resource
            </Link>
          </div>
        )}

        {isLoading ? (
          <div className="flex py-10 items-center text-gray-500 space-x-2 justify-center">
            <AiOutlineLoading3Quarters className="animate-spin" />{" "}
            <p>Loading...</p>
          </div>
        ) : error ? (
          <div className="text-center text-red-500">Error while fetching!</div>
        ) : (
          <>
            {resources.resources.length === 0 ? (
              <div className="text-center text-gray-500">
                No resources found!
              </div>
            ) : (
              resources.resources.map((resource) => {
                return (
                  <div key={resource?._id} className="mb-12">
                    <ResourceCard resource={resource} />
                  </div>
                );
              })
            )}
            <Pagination
              onPageChange={onPageChange}
              currentPage={params.page}
              totalCount={resources.totalCount}
            />
          </>
        )}
      </div>

      <div className="col-span-3 shadow-[-2px_2px_35px_-20px_rgba(0,0,0,0.3)] relative mt-16 py-2 p-7">
        <div className="sticky top-0">
          <Sidebar onSearch={onSearch} onFilter={onFilter} />
        </div>
      </div>
    </div>
  );
};

export default Resources;
