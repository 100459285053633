import ModalImage from "react-modal-image";
const Grid = ({ images }) => {
  const size = images.length;
  const span = size % 2 && size > 2

  return (
    <div
      className={`grid ${size === 1 ? "grid-cols-1": size >= 2 && size <=4 ? "grid-cols-2 " : size < 7? "grid-cols-3 ": "grid-cols-4"} `}
    >
      {images.map((img, index) => {
        return (
          <div key={index} className={` ${span && index === 0 ? "col-span-2 w-full" : " w-full "}  mx-auto`}>
            <ModalImage
            key={index}
            className ={` ${size > 2 ?"h-40 sm:h-60 ":"h-72 sm:h-96" } w-full object-cover`}
            small={img}
            large={img}
            alt=""
          />
          </div>
          
        );
      })}
    </div>
  );
};

export default Grid;
