const Tags = ({tags}) => {
  return (
    <div className="flex flex-wrap my-4">
      {tags.map((tag, index) => {
        return (
          <p key={index} className="px-4 mr-4 py-0.5 my-1 text-sm border bg-blue-100 rounded text-primary">
            {tag}
          </p>
        );
      })}
    </div>
  );
};

export default Tags;
