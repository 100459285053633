import { useGetResourceCountQuery } from "../../../services/resourceApi";
import Underline from "../../common/underline";
import NumberCounter from "./NumberCounter";

const OurReach = () => {
  const { data } = useGetResourceCountQuery();
  let community = 3000;
  let staff = 55;
  let resources = 0;
  if (data) {
    resources = data.length;
  }

  return (
    <section className="my-10 ">
      <h2 className="flex justify-center uppercase header-3">Our Reach</h2>
      <Underline color={"bg-primary"} />
      <div className="my-12 bg-blue-100 px-4">
        <div className="w-3/4 mx-auto flex flex-col  sm:flex-row justify-between py-16 sm:space-x-10">
          <div className="text-4xl text-gray-800 font-bold flex items-center flex-col justify-center">
            <img className="w-44" src="../../image/landing/staff.png" alt="" />

            <div className="flex justify-between items-center">
              <NumberCounter number={staff} /> +
            </div>

            <p className="text-xl my-6 font-semibold text-primary">
              Staff members
            </p>
          </div>
          <div className="text-4xl text-gray-800 font-bold flex items-center flex-col justify-center">
            <img className="w-44" src="../../image/landing/users.png" alt="" />
            <div className="flex justify-between items-center">
              <NumberCounter number={community} />+
            </div>

            <p className="text-xl my-6 font-semibold text-primary">
              Community members
            </p>
          </div>
          <div className="text-4xl text-gray-800 font-bold flex items-center flex-col justify-center">
            <img className="w-44" src="../../image/landing/books.png" alt="" />
            <NumberCounter number={resources} />
            <p className="text-xl my-6 font-semibold text-primary">
              Educational Resources
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurReach;
