import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://backend.alberttutorial.com/api/activities`,
    tagTypes: ["Activity", "Comment"],
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", "Bearer " + token);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getActivities: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
      }),
      providesTags: ["Activity"],
    }),
    getSingleActivity: builder.query({
      query: (activityId) => ({
        url: `/${activityId}`,
        method: "GET",
      }),
      providesTags: ["Activity"],
    }),

    createActivity: builder.mutation({
      query: (activity) => ({
        url: "/",
        method: "POST",
        body: activity,
      }),
      invalidatesTags: ["Activity"],
    }),
    likeActivity: builder.mutation({
      query: (activityId) => ({
        url: `/${activityId}/like`,
        method: "POST",
      }),
      invalidatesTags: ["Activity"],
    }),
    deleteActivity: builder.mutation({
      query: (activityId) => ({
        url: `/${activityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Activity"],
    }),
    getComments: builder.query({
      query: (activityId) => ({
        url: `/${activityId}/comments`,
        method: "GET",
      }),
      providesTags: ["Comment"],
    }),
    addComment: builder.mutation({
      query: ({ activityId, content }) => ({
        url: `/${activityId}/comments`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Comment","Activity"],
    }),
    deleteComment: builder.mutation({
      query: (commentId) => ({
        url: `/comments/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Comment","Activity"],
    }),
    likeComment: builder.mutation({
      query: (commentId) => ({
        url: `/comments/${commentId}/like`,
        method: "POST",
      }),
      invalidatesTags: ["Comment"],
    }),
  }),
});

export const {
  useGetActivitiesQuery,
  useLikeActivityMutation,
  useGetCommentsQuery,
  useLikeCommentMutation,
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetSingleActivityQuery,
} = activityApi;
