import dateFormatter from "../../../utils/dateFormatter";
import Tags from "./Tags";
import UserCard from "../../common/UserCard";

const PostDetail = ({ activity }) => {
  const verbalDate = dateFormatter(activity.createdAt);
  return (
    <div className=" px-4 sm:px-12">
      <div className="flex justify-between items-center border-b border-gray-200">
        <div className="py-3">
          <UserCard createdBy={activity.createdBy} />
        </div>
        <p className="text-gray-400 uppercase text-sm">{verbalDate}</p>
      </div>

      <Tags tags={activity.tags} />

      <p className=" pb-6 text-gray-600">{activity.description}</p>
    </div>
  );
};

export default PostDetail;
