const AboutCardRight = ({ title, year, description,image }) => {
  return (
    <div className="lg:flex-row items-center justify-between text-start p-4 bg-gray-10 rounded-lg flex flex-col-reverse lg:col-start-2 lg:col-span-3">
      <img
        className="w-80 shadow- m-8 p-2 rounded bg-gray-100"
        src={image}
        alt="clipart"
      />

      <div>
        <div className="border-primary px-2 border-l-4 my-6">
          <h4 className="text-primary font-semibold text-xl">{year}</h4>
          <h4 className="text-gray-700 font-semibold text-lg uppercase">
            {title}
          </h4>
        </div>

        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default AboutCardRight;
