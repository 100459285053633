import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

const NumberCounter = ({ number }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldCount, setShouldCount] = useState(false);
  const targetRef = useRef(null);
  const previousVisibleRef = useRef(false);

  useEffect(() => {
    const targetRefCurrent = targetRef.current; // Copy the ref value to a variable

    const observer = new IntersectionObserver(
      ([entry]) => {
        const isVisible = entry.isIntersecting;
        setIsVisible(isVisible);

        if (!previousVisibleRef.current && isVisible) {
          setShouldCount(true);
        }

        previousVisibleRef.current = isVisible;
      },
      { threshold: 0 } // Adjust threshold as needed
    );

    if (targetRefCurrent) {
      observer.observe(targetRefCurrent);
    }

    return () => {
      if (targetRefCurrent) {
        observer.unobserve(targetRefCurrent);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setShouldCount(false);
    }
  }, [isVisible]);

  return (
    <div ref={targetRef}>
      {shouldCount && <CountUp start={0} end={number} duration={2.5} />}
    </div>
  );
};

export default NumberCounter;
