import { useState } from "react";
import { Chips } from "primereact/chips";
import { ToastContainer, toast } from "react-toastify";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useCreateActivityMutation } from "../services/activityApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AddPost = () => {
  const { userRole } = useSelector((state) => state.auth);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [stringTag, setStringTag] = useState("");
  const [createActivity, { isLoading, isError }] = useCreateActivityMutation();
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("description", description);

    images.forEach((image) => {
      formData.append("images", image);
    });

    let trimmedString = stringTag.trim();
    let tagsArray = trimmedString.split(/\s*,\s*/);

    tagsArray.forEach((tag) => {
      if (tag !== "") {
        formData.append("tags", tag);
      }
    });
    tags.forEach((tag) => {
      formData.append("tags", tag);
    });

    if (isError) {
      toast.error("Error occurred!");
    }

    try {
      if (!isLoading) {
        const response = await createActivity(formData).unwrap();
        if (response) {
          toast.success("You have created post successfully!");
          setTimeout(() => {
            navigate("/posts");
          }, 1000);
        }
      }

      setDescription("");
      setImages([]);
      setTags([]);
      setStringTag("");
    } catch (error) {
      const message = error?.data?.message || "Something went wrong!";
      toast.error(message);
    }
  };

  if (userRole !== "staff") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-2xl text-gray-500">
          You are not authorized to access this page!
        </p>
        <p className="text-sm my-4 text-gray-500">
          redirecting to home page...
        </p>
        {setTimeout(() => {
          navigate("/");
        }, 4000)}
      </div>
    );
  }

  const descriptionWordCount = description.trim().split(/\s+/).length;
  return (
    <form
      className="max-w-lg mx-auto my-40 px-2 w-full"
      onSubmit={handleSubmit}
    >
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="description"
        >
          Description:
          <span className="text-xs text-gray-500 font-normal">
            We recommend writing a description of around 30 to 50 words, but
            feel free to write as much as you'd like. You can use ChatGPT for
            content generation.
          </span>
        </label>
        <textarea
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full h-32 resize-none"
          id="description"
          value={description}
          onChange={handleDescriptionChange}
        />
        <p className="text-xs text-end text-gray-500">
          {descriptionWordCount} words
        </p>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="images"
        >
          Images:
          <span className="text-xs text-gray-500 font-normal">
            Please don't upload more than 6 images! The Size of each image must be less than 2MB.
            It is recommended to upload images with less size for better performance.
          </span>
        </label>
        <input
          className="border w-full border-gray-300 rounded-md p-2 mb-2 outline-none focus:border-gray-500"
          type="file"
          id="images"
          multiple
          onChange={handleImageChange}
        />

        {images?.length > 0 && (
          <div>
            {images.map((image, index) => (
              <p
                className="bg-gray-100 text-gray-600 text-sm p-1 px-4 rounded my-1"
                key={index}
              >
                {image.name}
              </p>
            ))}
          </div>
        )}
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="tags"
        >
          Tags:
          <span className="text-xs text-gray-500 font-normal">
            Add more descriptive tags. Use comma[on Mobile] or enter key [on PC] as separator for tags.
          </span>
        </label>
        <div className="hidden md:inline-block">
          <Chips
            value={tags}
            onChange={(e) => setTags(e.value)}
            separator=","
          />
        </div>
        <input
          className="md:hidden border w-full border-gray-300 rounded-md p-2 mb-2 outline-none focus:border-gray-500"
          type="text"
          onChange={(e) => setStringTag(e.target.value)}
        />
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        {isLoading ? "Submitting..." : "Submit"}
      </button>
      <ToastContainer />
    </form>
  );
};

export default AddPost;
