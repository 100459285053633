import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useGetOneMemberQuery,
  useUpdateMemberMutation,
} from "../services/memberApi";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const EditTeamMember = () => {
  const { id: memberId } = useParams();
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state.auth);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [bio, setBio] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [telegram, setTelegram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [position, setPosition] = useState("");
  const [order, setOrder] = useState("100");
  const [currentWhereabout, setCurrentWhereabout] = useState("");

  const [updateMember, { isLoading, isError }] = useUpdateMemberMutation();
  const { data: memberDetails, isLoading: fetchingById } =
    useGetOneMemberQuery(memberId);

  useEffect(() => {
    if (memberDetails) {
      setName(memberDetails?.name);
      setImage(memberDetails?.image);
      setBio(memberDetails?.bio);
      setLinkedIn(memberDetails?.contacts?.linkedIn);
      setTelegram(memberDetails?.contacts?.telegram);
      setFacebook(memberDetails?.contacts?.facebook);
      setPosition(memberDetails?.position);
      setOrder(memberDetails?.order);
      setCurrentWhereabout(memberDetails?.currentWhereabout);
    }
  }, [memberDetails]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isError) {
      toast.error("Error occurred!");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    if (image) {
      formData.append("image", image);
    }

    formData.append("bio", bio);
    formData.append("position", position);
    formData.append("order", order);
    formData.append("currentWhereabout", currentWhereabout);

    if (linkedIn) {
      formData.append("contacts[linkedIn]", linkedIn);
    }

    if (telegram) {
      formData.append("contacts[telegram]", telegram);
    }

    if (facebook) {
      formData.append("contacts[facebook]", facebook);
    }

    try {
      const response = await updateMember({
        id: memberId,
        member: formData,
      }).unwrap();
      if (response) {
        toast.success("Team member updated successfully!");

        setTimeout(() => {
          navigate("/our-team");
        }, 500);
      }
    } catch (error) {
      const message = error?.data?.message || "Something went wrong!";
      toast.error(message);
    }
  };

  if (fetchingById) {
    return (
      <div className="min-h-screen text-center my-auto">
        Feaching the user...
      </div>
    );
  }

  if (userRole !== "admin") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-2xl text-gray-500">
          You are not authorized to access this page
        </p>
        <p className="text-sm my-4 text-gray-500">
          redirecting to home page...
        </p>
        {setTimeout(() => {
          navigate("/");
        }, 3000)}
      </div>
    );
  }

  return (
    <form className="max-w-lg mx-auto my-40 w-full" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Name:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="image"
        >
          Image:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          type="file"
          id="image"
          onChange={handleImageChange}
        />
      </div>

      {/* Bio */}
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="bio"
        >
          Bio:
        </label>
        <textarea
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full resize-none"
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </div>

      {/* LinkedIn */}
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="linkedin"
        >
          LinkedIn:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="linkedin"
          value={linkedIn}
          onChange={(e) => setLinkedIn(e.target.value)}
        />
      </div>

      {/* Telegram */}
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="telegram"
        >
          Telegram:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="telegram"
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
        />
      </div>

      {/* Facebook */}
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="facebook"
        >
          Facebook:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="facebook"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="position"
        >
          Position:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />
      </div>

      {/* Order */}
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="order"
        >
          Order:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="order"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="whereabout"
        >
          Current Whereabout:
        </label>
        <input
          className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
          id="whereabout"
          value={currentWhereabout}
          onChange={(e) => setCurrentWhereabout(e.target.value)}
        />
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        {isLoading ? "Editing Member..." : "Edit Member"}
      </button>
      <ToastContainer />
    </form>
  );
};

export default EditTeamMember;
