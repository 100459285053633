import { Link } from "react-router-dom";
import Underline from "../../common/underline";

const AboutBrief = () => {
  return (
    <section className="mb-10">
      <h2 className="flex justify-center uppercase header-3">Who We Are</h2>
      <Underline color={"bg-primary"} />
      <div className="my-14 flex flex-col py-6 sm:py-0 sm:flex-row sm:divide-x divide-blue-200 sm:space-x-10 bg-blue-100 px-4 sm:px-20">
        <div className="bg-gradient-to-l basis-1/2 from-blue-200 rounded-full">
          <img className="mx-auto w-full" src="../../image/landing/about.png" alt="A student" />
        </div>
        <div className="basis-1/2 sm:pl-10 my-6 sm:my-auto text-center sm:text-start">
          <h2 className="flex flex-col sm:leading-loose justify-center uppercase font-semibold text-2xl sm:text-3xl">
            Empowering Students
            <span className="text-4xl text-primary">Learn Our Story</span>
          </h2>
          <p className="my-8 text-lg text-secondary-text">
            Discover the story behind our mission to empower students through
            free education. Visit our About page to learn more and join us on
            this transformative journey.
          </p>
          <Link to={"/about"} className="btn-outline duration-700 mt-10">
            Learn more about us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutBrief;
