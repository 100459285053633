import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import {
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useLikeCommentMutation,
} from "../../../services/activityApi";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dateFormatter from "../../../utils/dateFormatter";
import UserCard from "../../common/UserCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../common/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import CommentBox from "./CommentBox";

const CommentSection = ({ activityId }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: comments, isLoading, error } = useGetCommentsQuery(activityId);
  const [likeComment] = useLikeCommentMutation();
  const { userId, userToken } = useSelector((state) => state.auth);
  const [likeLoading, setLikeLoading] = useState(null);

  const [deleteComment, { isLoading: isDeleting }] = useDeleteCommentMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleLike = async (id) => {
    try {
      await likeComment(id);
    } catch (error) {
      toast.error("Something went wrong while liking the comment!");
    }
  };

  if (isLoading) {
    return (
      <div className="w-full flex justify-center my-2">
        <AiOutlineLoading3Quarters className="animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full flex justify-center my-2">
        <p>Error occured</p>
      </div>
    );
  }

  return (
    <div>
      {userToken ? (
        <CommentBox activityId={activityId} />
      ) : (
        <button
          className="hover:underline px-10 border bg-primary text-gray-50 py-2 mt-4"
          onClick={() =>
            navigate("/login", {
              state: { from: location.pathname },
            })
          }
        >
          Login to comment
        </button>
      )}

      <div className="max-h-96 overflow-y-scroll">
        {comments.length === 0 ? (
          <div className="w-full flex justify-center my-2 text-gray-500">
            <p>No comments yet!</p>
          </div>
        ) : (
          comments.map((comment, index) => {
            const verbalDate = dateFormatter(comment.createdAt);
            const isLiked = comment.likes.includes(userId);
            const isMyComment = comment.createdBy._id === userId;

            return (
              <div
                key={index}
                className="bg-gray-100 my-4 mx-8 rounded-lg p-4 py-2 pt-0"
              >
                <div className="flex items-end justify-between">
                  <UserCard createdBy={comment.createdBy} />
                  <p className="py-5 uppercase text-xs text-gray-500">
                    {verbalDate}
                  </p>
                </div>
                <div className="text-sm">
                  <p className="text-gray-800 my-2 max-h-28 overflow-y-scroll">
                    {comment.content}
                  </p>
                </div>
                <div className="flex basis-1/3 items-center space-x-4">
                  <button
                    className="h-6 w-4"
                    onClick={async () => {
                      setLikeLoading(comment._id);
                      if (!userToken) {
                        setShowModal(true);
                      } else {
                        await handleLike(comment._id);
                      }
                      setLikeLoading(null);
                    }}
                  >
                    {likeLoading === comment._id ? (
                      <AiOutlineLoading3Quarters className="animate-spin m-1" />
                    ) : isLiked ? (
                      <AiFillHeart className="text-red-500 text-xl" />
                    ) : (
                      <AiOutlineHeart className="text-xl text-gray-500" />
                    )}
                  </button>

                  {showModal && (
                    <Modal
                      setOpenModal={setShowModal}
                      handleFunction={() =>
                        navigate("/login", {
                          state: { from: location.pathname },
                        })
                      }
                      data={{
                        title: "Want Login ?",
                        buttonText: "Login",
                        description: "You should log in before giving a like",
                      }}
                    />
                  )}
                  <div className="flex flex-row w-full justify-between items-center">
                    <p className="text-gray-400 text-xs">
                      {comment.likes.length}
                      {comment.likes.length === 1 ? " Like" : " Likes"}
                    </p>

                    {isMyComment && (
                      <button
                        className="text-red-500 text-xs text-end"
                        onClick={async () => {
                          await deleteComment(comment._id);
                        }}
                      >
                        {isDeleting ? (
                          <AiOutlineLoading3Quarters className="animate-spin" />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CommentSection;
