const OurMission = () => {
  return (
    <div className="flex flex-col md:flex-row items-center border-t border-gray-300 bg-blue-200 py-16 shadow-lg mt-10 px-10 md:px-20 text-gray-700">
      <img className = "sm:inline md:hidden lg:inline w-80" src="../image/about/mission.png" alt="" />

      <div className="lg:mx-20">
        <h2 className="text-xl font-medium my-4 text-gray-800 uppercase">
          Our Mission
        </h2>
        <p className="pb-4 ">
          The main mission of ATVC is to make education accessible to everyone
          despite any financial hurdles and to produce knowledgeable and
          disciplined students who can create a significant change in Ethiopia.
        </p>

        <h2 className="text-xl font-medium my-4 text-gray-800 uppercase">
          Our Principle
        </h2>
        <p className="pb-4 ">
          Serve the nation with a strong sense of patriotism by spreading
          honesty and the idea of giving back to the community for the
          betterment of many lives.
        </p>

        <h2 className="text-xl font-medium my-4 text-gray-800 uppercase">
          Our Goal
        </h2>
        <p className="pb-4 ">
          To strengthen the program and reach a larger number of students across
          various regions of Ethiopia, ATVC aims to open branches in different
          parts of the country, thereby encouraging more students to engage in
          voluntary work. Additionally, ATVC is committed to providing
          comprehensive material support to students in all regions of Ethiopia.
        </p>
      </div>
    </div>
  );
};

export default OurMission;
