import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAddMemberMutation } from "../services/memberApi";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetUserCountQuery } from "../services/authApi";

const AddTeamMember = () => {
  const {data} = useGetUserCountQuery();
  let community = 0
  let staff = 0
  if(data && data?.length > 0 ){
    data.map(each => {
      if (each._id === "staff"){
        staff = each.count
      }else if(each._id === "community"){
        community = each.count
      }
      return null
    })
  }

  const params = useParams();

  console.log(params, "team and id");

  const { userRole } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [bio, setBio] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [telegram, setTelegram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [position, setPosition] = useState("");
  const [order, setOrder] = useState("100");
  const [currentWhereabout, setCurrentWhereabout] = useState("");

  const [addMember, { isLoading, isError }] = useAddMemberMutation();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isError) {
      toast.error("Error occurred!");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("bio", bio);
    formData.append("position", position);
    formData.append("order", order);
    formData.append("currentWhereabout", currentWhereabout);

    formData.append("contacts[linkedIn]", linkedIn);
    formData.append("contacts[telegram]", telegram);
    formData.append("contacts[facebook]", facebook);

    try {
      const response = await addMember(formData).unwrap();
      console.log(response);
      if (response) {
        toast.success("Team member added successfully!");
      }
    } catch (error) {
      const message = error?.data?.message || "Something went wrong!";
      toast.error(message);
    }
  };
  if (userRole !== "admin") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-2xl text-gray-500">
          You are not authorized to access this page
        </p>
        <p className="text-sm my-4 text-gray-500">
          redirecting to home page...
        </p>
        {setTimeout(() => {
          navigate("/");
        }, 3000)}
      </div>
    );
  }

  return (
    <form className="flex md:flex-row flex-col mx-auto my-40 w-full" onSubmit={handleSubmit}>
      <div className="basis-1/12 p-2">
        <h1 className="text font-bold my-2">Users</h1>
        <div>
          <p className="text-sm">Community: {community}</p>
          <p className="text-sm">Staff: {staff}</p>
        </div>
        
      </div>
      <div className="basis-6/12 mx-auto">
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* Image URL */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="image"
          >
            Image:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            type="file"
            id="image"
            onChange={handleImageChange}
          />
        </div>

        {/* Bio */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="bio"
          >
            Bio:
          </label>
          <textarea
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full resize-none"
            id="bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>

        {/* LinkedIn */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="linkedin"
          >
            LinkedIn:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="linkedin"
            value={linkedIn}
            onChange={(e) => setLinkedIn(e.target.value)}
          />
        </div>

        {/* Telegram */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="telegram"
          >
            Telegram:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="telegram"
            value={telegram}
            onChange={(e) => setTelegram(e.target.value)}
          />
        </div>

        {/* Facebook */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="facebook"
          >
            Facebook:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
        </div>

        {/* Position */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="position"
          >
            Position:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </div>

        {/* Order */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="order"
          >
            Order:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="order"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          />
        </div>

        {/* Current Whereabout */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="whereabout"
          >
            Current Whereabout:
          </label>
          <input
            className="border outline-none text-gray-700 focus:border-gray-400 border-gray-300 rounded-md p-2 w-full"
            id="whereabout"
            value={currentWhereabout}
            onChange={(e) => setCurrentWhereabout(e.target.value)}
          />
        </div>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          {isLoading ? "Adding Member..." : "Add Member"}
        </button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default AddTeamMember;
