import { MdVolunteerActivism, MdOutlineCastForEducation } from "react-icons/md";
import { TbFreeRights } from "react-icons/tb";

import Underline from "../../common/underline";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Intro = () => {
  const { userToken } = useSelector((state) => state.auth);
  return (
    <section>
      <div className="py-20 flex-col-reverse sm:px-32 shadow-sm  items-center border-b bg-slate-50 flex sm:flex-row ">
        <div className="basis-1/2 text-center">
          <h1 className="text-3xl sm:text-5xl sm:leading-snug font-bold">
            Empowering <span className="text-primary ">Students,</span>
            Encouraging Volunteerism
            
          </h1>
          { !userToken &&
          <button className="mt-14">
            <Link
              to={"/register"}
              className="btn-outline flex justify-center items-center font-semibold duration-700"
            >
              Sign up for free
            </Link>
          </button> }
        </div>
        <div className="basis-1/2 pl-8">
          <img src="../../image/landing/image1.png" alt="students" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center my-12 sm:my-24">
        <div className="hidden sm:visible">
          <Underline color={"bg-gray-300"} />
        </div>

        <div className="bg-slate-50  shadow-sm p-8 w-full  ">
          <div className="text-center border-b  py-5">
            <h1 className="text-center text-2xl text-primary font-semibold uppercase ">
              Albert Tutorial and Volunteerism Center
            </h1>
            <p className="text-gray-600 text-lg py-4 sm:px-20">
            Offering summer tutoring
              for grades 7-12 students in Adama Town. We also assist
              underprivileged students who lack resources for educational
              materials. Empowering education for all.
            </p>
          </div>
          <div className="sm:w-3/5 flex flex-col mt-5 sm:flex-row sm:mx-auto justify-between">
            <div className="flex justify-center items-center my-3 sm:my-0 space-x-4 text-primary font-bold">
              <MdVolunteerActivism className="text-2xl" />
              <p className="text-tertiary-text">VOLUNTEER</p>
            </div>
            <div className="flex justify-center items-center my-3 sm:my-0 space-x-2 text-primary font-bold">
              <MdOutlineCastForEducation className="text-2xl" />
              <p className="text-tertiary-text">EDUCATION</p>
            </div>
            <div className="flex justify-center items-center my-3 sm:my-0 space-x-2 text-primary font-bold">
              <TbFreeRights className="text-2xl" />
              <p className="text-tertiary-text">FOR FREE</p>
            </div>
          </div>
        </div>
        <div className="hidden sm:visible">
          <Underline color={"bg-gray-300"} />
        </div>
      </div>
    </section>
  );
};

export default Intro;
