import Grid from "../components/pages/posts/Grid";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useGetSingleActivityQuery } from "../services/activityApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import PostDetail from "../components/pages/posts/PostDetail";
import PostInteraction from "../components/pages/posts/PostInteraction";
import { Link, useParams } from "react-router-dom";

const SinglePost = () => {
  const { id } = useParams();
  const { data: activity, isLoading, isError } = useGetSingleActivityQuery(id);
  if (activity === null) {
    return (
      <div className="text-center flex flex-col justify-center text-red-500 min-h-screen">
        <p>Error while fetching. May the post have been deleted!</p>
      </div>
    );
  }

  return (
    <section className="py-24 overflow-x-hidden">
      <Link
        to="/posts"
        className="bg-primary hover:bg-blue-950 px-3 py-2 my-2 text-white"
      >
        Back to Posts
      </Link>
      {isLoading ? (
        <div className="flex py-10 items-center space-x-2 justify-center">
          <AiOutlineLoading3Quarters className="animate-spin" />
          <p>Loading...</p>
        </div>
      ) : isError ? (
        <div className="text-center text-red-500">Error while fetching</div>
      ) : (
        <div
          key={activity?._id}
          className="col-span-2 my-10 mx-4 lg:w-3/5 sm:mx-auto shadow bg-gray-50 rounded pb-6"
        >
          <Grid images={activity?.images} />
          <PostDetail activity={activity} />
          <PostInteraction activity={activity} />
        </div>
      )}
      <ToastContainer />
    </section>
  );
};

export default SinglePost;
