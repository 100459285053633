import Discussion from "../components/pages/contact/Discussion";
import EmailSection from "../components/pages/contact/EmailSection";

const Contact = () => {
  return (
    <div className="grid lg:grid-cols-12 grid-cols-1 bg-gray-50 min-h-screen ">
      <div className="lg:col-span-7 lg:pb-20 max-h-[150vh] lg:max-h-none overflow-y-scroll lg:overflow-hidden">
        <Discussion />
      </div>
      
      <div className="col-span-5 relative my-10 mt-16 py-2 lg:mr-16 px-6 lg:px-0">
        <div className="sticky top-0 ">
         <EmailSection />
        </div>
       
      </div>
    </div>
  );
};

export default Contact;
