
import RecentActivities from "../components/pages/landing/RecentActivities";
import OurReach from "../components/pages/landing/OurReach";
import Service from "../components/pages/landing/Service";
import AboutBrief from "../components/pages/landing/AboutBrief";
import Intro from "../components/pages/landing/Intro";

const Landing = () => {
  return (
    <>
      <Intro />
      <AboutBrief />
      <Service />
      <OurReach />
      <RecentActivities />
    </>
  );
};

export default Landing;
