import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="sm:px-40 mt-auto sm:relative">
      <img className="w-full sm:object-contain" src="../image/notfound.png" alt="" />
      <Link to={"/"} className="text-primary text-xl hover:underline sm:absolute top-1/3">Back to home</Link>
    </div>
  );
};

export default NotFound;
