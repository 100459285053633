import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FiUser,
  FiMail,
  FiLock,
  FiEye,
  FiEyeOff,
  FiArrowLeft,
} from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useRegisterMutation } from "../services/authApi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GoogleAuth from "../components/common/GoogleButton";

const Register = () => {
  const [register, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    registerAsStaff: false,
    secretKey: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { name, email, password, confirmPassword, registerAsStaff, secretKey } =
    formData;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (name === "email") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value.trim().toLowerCase(),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!name || !email || !password || !confirmPassword) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return;
    }
    // Handle signup logic here
    if (registerAsStaff && !secretKey) {
      toast.error("Please enter the secret code.");
      return;
    }

    try {
      await register(formData).unwrap();
      toast.success("Registered successfully!");
      setFormData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        registerAsStaff: false,
        secretKey: "",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      const message = error?.data?.message || "Something went wrong!";
      toast.error(message);
    }

    setFormData({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      registerAsStaff: false,
      secretKey: "",
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-between bg-gray-100 sm:px-10">
      <div>
        <img
          src={"../image/login-image.png"}
          alt="signup clipart"
          className="hidden sm:block w-125 h-125 ml-10"
        />
      </div>

      <div className="max-w-md w-full mx-auto my-auto py-20 p-6">
        <Link className="md:hidden" to={"/"}>
          <FiArrowLeft />
        </Link>
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold mb-2">Sign up</h2>
            <p className="text-gray-600">
              Already have an account?
              <a href="/login" className="text-primary">
                {" "}
                Login
              </a>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiUser />
              </span>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Name"
                value={name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiMail />
              </span>
              <input
                type="text"
                id="email"
                name="email"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Email or Phone number"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiLock />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Password"
                value={password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="focus:outline-none ml-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
              <span className="text-gray-500 mr-2">
                <FiLock />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                className="w-full bg-gray-100 focus:outline-none"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="focus:outline-none ml-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>
          <div className="mb-4 flex">
            <input
              type="checkbox"
              id="staffRegistration"
              name="registerAsStaff"
              className="mr-2 accent-primary"
              checked={registerAsStaff}
              onChange={handleChange}
            />
            <label htmlFor="staffRegistration" className="block text-gray-700">
              Staff Registration
            </label>
          </div>
          {registerAsStaff && (
            <div className="mb-4">
              <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
                <span className="text-gray-500 mr-2">
                  <FiLock />
                </span>
                <input
                  type="text"
                  id="secretKey"
                  name="secretKey"
                  className="w-full bg-gray-100 focus:outline-none"
                  placeholder="Secret Code"
                  value={secretKey}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-full flex justify-center items-center space-x-1 bg-primary text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <></>
            )}
            <p>Sign up</p>
          </button>{" "}
          <div class="inline-flex items-center my-6 justify-center w-full">
            <hr class="w-[70%] h-px mt-1.5 bg-gray-300 border-0" />
            <span class="absolute px-3 ml-12 -translate-x-1/2  bg-gray-100">
              or
            </span>
          </div>
          <div className="">
            <GoogleAuth />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
