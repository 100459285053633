import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PostIntro = () => {
  const { userToken } = useSelector((state) => state.auth);
  return (
    <div className=" flex flex-col justify-around md:py-0 md:flex-row bg-blue-100 px-4 md:px-20 py-16">
      <div className="my:10 hidden md:inline md:my-32 bg-gradient-to-l from-blue-200 rounded-full w-2/5">
        <img className="" src="../image/posts.png" alt="post animation" />
      </div>

      <div className="basis-1/2 md:pl-10 my-10 md:my-auto text-center md:text-start">
        <h2 className="md:leading-loose uppercase font-semibold text-2xl md:text-3xl">
          OUR ACTIVITIES
        </h2>
        <h1 className="text-3xl md:text-4xl text-primary uppercase font-semibold">
          Explore our page
        </h1>
        <p className="my-8 mb-20 text-lg text-secondary-text">
          Explore this page for a collection of inspiring and informative posts.
          From educational workshops to community outreach programs, we share
          stories, photos, and details about our initiatives. Join us on this
          journey of knowledge, compassion, and service as we strive to make a
          positive change in the world. Engage with the posts, leave comments,
          and be part of our mission to create a brighter future.
        </p>
        { !userToken &&
        <Link to={"/register"} className="btn-outline duration-700 ">
          Join our community!
        </Link>}
      </div>
    </div>
  );
};

export default PostIntro;
