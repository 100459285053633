const AboutCardLeft = ({title, year, description,image}) => {
  return (
    <div className="lg:flex-row items-center justify-between lg:text-end p-4 bg-gray-10 rounded-lg flex flex-col lg:col-end-4 lg:col-span-3">
      <div>
        <div className="border-primary px-2 border-l-4 lg:border-l-0 lg:border-r-4 my-6">
          <h4 className="text-primary font-semibold text-xl">{year}</h4>
          <h4 className="text-gray-700 font-semibold text-lg uppercase">
            {title}
          </h4>
        </div>

        <p className="text-gray-600">
          {description}
        </p>
      </div>

      <img
        className="w-80 shadow m-8 p-2 rounded bg-gray-100"
        src={image}
        alt="clipart"
      />
    </div>
  );
};

export default AboutCardLeft;
