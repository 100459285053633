import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import {
  RiShareForward2Fill,
  RiDeleteBin4Line,
  RiTelegramFill,
  RiFacebookFill,
  RiLinkedinFill,
} from "react-icons/ri";
import { BiComment } from "react-icons/bi";
import NoteModal from "../../common/NoteModal";
import Modal from "../../common/Modal";
import {
  useDeleteActivityMutation,
  useLikeActivityMutation,
} from "../../../services/activityApi";
import CommentSection from "./CommentSection";

const PostInteraction = ({ activity }) => {
  const [showNote, setShowNote] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [usersOnModal, setUsersOnModal] = useState(null);
  const { userToken, userId } = useSelector((state) => state.auth);
  const [likeLoading, setLikeLoading] = useState(null);
  const [openComment, setOpenComment] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showShareButtons, setShowShareButtons] = useState(false);

  const [likeActivity] = useLikeActivityMutation();
  const [deleteActivity, { isLoading: isDeleting }] =
    useDeleteActivityMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleLike = async (id) => {
    try {
      await likeActivity(id);
    } catch (error) {
      toast.error("Something went wrong while liking the post!");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteActivity(deleteId);
      if (response?.error?.data) {
        toast.error(response.error.data?.message);
        return;
      } else if (response?.error) {
        toast.error("Something went wrong while deleting the post!");
        return;
      }
      setShowModalDelete(false);
      toast.success("Post deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong while deleting the post!");
    }
  };

  const isLiked = activity.likes.map((like) => like._id).includes(userId);
  const isMyActivity = activity.createdBy._id === userId;
  const shareUrl = `${window.location.origin}/posts/single-post/${activity._id}`;
  return (
    <div>
      
      <div className="flex items-center pt-4 border-t px-6 sm:px-12 bg-gray-50">
        <div className="flex basis-1/3 items-center space-x-4">
          <button
            className="h-6 w-4"
            onClick={async () => {
              setLikeLoading(activity._id);
              if (!userToken) {
                setShowModal(true);
              } else {
                await handleLike(activity._id);
              }
              setLikeLoading(null);
            }}
          >
            {likeLoading === activity._id ? (
              <AiOutlineLoading3Quarters className="animate-spin m-1" />
            ) : isLiked ? (
              <AiFillHeart className="text-red-500 text-2xl" />
            ) : (
              <AiOutlineHeart className="text-2xl text-gray-500" />
            )}
          </button>

          {showNote && (
            <NoteModal
              setOpenModal={setShowNote}
              title="Likes of this post"
              array={usersOnModal}
            />
          )}

          {showModal && (
            <Modal
              setOpenModal={setShowModal}
              handleFunction={() =>
                navigate("/login", {
                  state: { from: location.pathname },
                })
              }
              data={{
                title: "Want Login ?",
                buttonText: "Login",
                description: "You should log in before giving a like",
              }}
            />
          )}
          <button
            className="text-gray-500 hover:text-primary transition-all duration-300 flex space-x-2 hover:underline text-sm"
            onClick={() => {
              setUsersOnModal(activity.likes);
              setShowNote(true);
            }}
          >
            <p>{activity.likes.length}</p>
            <span className="hidden sm:block">
              {activity.likes.length === 1 ? " Like" : " Likes"}
            </span>
          </button>
        </div>
        <button
          onClick={() => setOpenComment(activity._id)}
          className="flex mx-auto space-x-2 basis-1/3 text-gray-500 hover:text-primary transition-all duration-300"
        >
          <BiComment className="text-xl" />
          <div className="text-sm flex space-x-2 items-center ">
            <p>{activity.commentCount}</p>
            <span className="hidden sm:block"> Comments</span>
          </div>
        </button>
        <div>
      <div className="relative flex items-center space-x-2 ml-auto">
        <button
          onClick={() => setShowShareButtons(!showShareButtons)}
          className="flex items-center space-x-1 focus:outline-none text-gray-500 hover:text-primary transition-all duration-300"
        >
          <RiShareForward2Fill className="text-xl " />
          <p className="text-sm">Share</p>
        </button>

        {showShareButtons && (
          <div className="bg-gray-100 border py-1 px-2 rounded absolute -top-14 -right-6 flex justify-between items-center mt-4 space-x-6 transition-transform duration-1000 transform translate-y-0">

            <TelegramShareButton title={activity.description} url={shareUrl}>
              <RiTelegramFill className="text-primary" size={24} />
            </TelegramShareButton>

            <FacebookShareButton
              quote="Free_Education"
              hashtag="Albert_Tutorial"
              url={shareUrl}
            >
              <RiFacebookFill className="text-primary" size={24} />
            </FacebookShareButton>

            {/* LinkedIn Share Button */}
            <LinkedinShareButton
              url={shareUrl}
              title="Albert Tutorial and volunteerism"
              summary={activity.description}
            >
              <RiLinkedinFill className="text-primary" size={24} />
            </LinkedinShareButton>
          </div>
        )}
      </div>
    </div>

        {isMyActivity && (
          <button
            onClick={() => {
              setDeleteId(activity._id);
              setShowModalDelete(true);
            }}
            className="flex items-center space-x-2 ml-auto "
          >
            <RiDeleteBin4Line className="text-xl text-red-500" />
            <p className="text-sm text-red-500">Delete</p>
          </button>
        )}
        {showModalDelete && (
          <Modal
            setOpenModal={setShowModalDelete}
            handleFunction={() => {
              handleDelete();
            }}
            data={{
              title: "Want Delete ?",
              buttonText: isDeleting ? "Deleting..." : "Delete",
              description: "Are you sure to delete this post ?",
            }}
            color="bg-red-500"
          />
        )}
      </div>
      {openComment === activity._id && (
        <CommentSection activityId={activity._id} />
      )}

      <ToastContainer />
    </div>
  );
};

export default PostInteraction;
