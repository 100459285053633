import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const resourceApi = createApi({
  reducerPath: "resourceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://backend.alberttutorial.com/api/resources`,
    tagTypes: ["Resources"],
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set(
          "authorization",
          "Bearer " +
            "eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJwb29sIjoicG9jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2FxeW4tNDI3ODIzIiwiYXVkIjoiYXF5bi00Mjc4MjMiLCJhdXRoX3RpbWUiOjE3MjQ2NzY5OTMsInVzZXJfaWQiOiJMZmF4MWlLN1BjVkkxaGczNEVuRlFoMllISkIzIiwic3ViIjoiTGZheDFpSzdQY1ZJMWhnMzRFbkZRaDJZSEpCMyIsImlhdCI6MTcyNDY3Njk5MywiZXhwIjoxNzI0NjgwNTkzLCJlbWFpbCI6ImplYmVzc2FkZWplbmUyMDIwQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJqZWJlc3NhZGVqZW5lMjAyMEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.fdME2h5vQIYfLEWAF8oImz327Mv2nX6s6tR5Sem3upSYKkWo2CwS2cqWJlHvGev9DnByghdGKmkyTMG5wX3G6ZthCkGZv9XSyVuPyl-WrJUORPlC5bKgQPMBkYkreJ4VxyXpMOUbwdW0UMOMaQV88WFQCLvIFO8guPLNpcVb_hkL9kB3PT97T6-tpEIpud58cBzaXst-CJYT4IL98V8CeC0RukT2AKGWfAAaj6ig1SInUQoS32E8H3gxApzMCZMD1JqhAp6v7X9NiSr7wv4wu0eDPi35tDSxJ5leKPWDwAOSvmn-1j8CQI2Nf8Ya4T-Z8Jp6iHoQKO_Xe8L4swDFYg"
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getResources: builder.query({
      query: (params) => ({
        url: "/",
        params,
      }),
      providesTags: ["Resources"],
    }),
    createResource: builder.mutation({
      query: (resource) => ({
        url: "/",
        method: "POST",
        body: resource,
      }),
      invalidatesTags: ["Resources"],
    }),
    updateResource: builder.mutation({
      query: ({ id, resource }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: resource,
      }),
      invalidatesTags: ["Resources"],
    }),
    deleteResource: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Resources"],
    }),
    getSingleResource: builder.query({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Resources"],
    }),
    getMyResources: builder.query({
      query: () => ({
        url: "/my-resources",
      }),
      providesTags: ["Resources"],
    }),

    getResourceCount: builder.query({
      query: () => ({
        url: "/count",
      }),
      providesTags: ["Resources"],
    }),
    likeResource: builder.mutation({
      query: (id) => ({
        url: `/${id}/like`,
        method: "POST",
      }),
      invalidatesTags: ["Resources"],
    }),
  }),
});

export const {
  useGetResourcesQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useDeleteResourceMutation,
  useGetSingleResourceQuery,
  useGetMyResourcesQuery,
  useLikeResourceMutation,
  useGetResourceCountQuery,
} = resourceApi;
