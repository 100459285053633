import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link } from "react-router-dom";
import Underline from "../../common/underline";
import { useGetActivitiesQuery } from "../../../services/activityApi";
import UserCard from "../../common/UserCard";
import Tags from "../posts/Tags";
import dateFormatter from "../../../utils/dateFormatter";

const RecentActivities = () => {
  const { data: activities, isLoading, error } = useGetActivitiesQuery();
  const recentActivities = activities?.slice(0, 4);
  if (recentActivities?.length === 0){
    return <div></div>
  }

  return (
    <section className="my-10 px-3">
      <h2 className="flex justify-center uppercase header-3">Recent Posts</h2>
      <Underline color={"bg-primary"} />
      {isLoading ? (
        <div className="w-full  flex justify-center mx-auto">
          <AiOutlineLoading3Quarters className="animate-spin text-xl mx-auto" />
        </div>
      ) : error ? (
        <div className="text-sm text-center mx-auto">
          Error occured while Fetching!
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 md:px-24 gap-4 md:gap-8 mt-20">
          {recentActivities.map((activity, index) => {
            const verbalDate = dateFormatter(activity.createdAt);

            return (
              <div
                className=" flex flex-col my-auto bg-gray-100 shadow-sm hover:shadow-lg"
                key={index}
              >
                {activity.images.length > 0 && (
                  <img
                    className="object-cover rounded-t-md w-full h-52"
                    src={activity.images[0]}
                    alt={`recent activities ${index}`}
                  />
                )}
                <div className="px-4">
                  <div className="px-2 border-b py-1">
                    <Tags tags={activity.tags} />
                  </div>

                  <p className={`text-sm my-5 text-gray-600 ${activity.images.length > 0 ? "h-16 ":" py-auto"}  overflow-y-scroll`}>
                    {activity.description}
                  </p>
                  <div className="flex bg-gray-50 items-center space-x-2 py-2 rounded justify-evenly border-gray-200 shadow-sm">
                    <UserCard createdBy={activity.createdBy} />
                    <span className="text-gray-500">|</span>
                    <p className="text-gray-500 border-gray-400 pl-3 text-xs">
                      {verbalDate}
                    </p>
                  </div>
                  <div className="flex justify-between items-center py-4 px-3">
                    <p className="text-sm  text-gray-600">
                      {activity.likes.length} Likes
                    </p>

                    <Link
                      className="font-semibold text-sm text-primary"
                      to={"/posts"}
                    >
                      Get More posts
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default RecentActivities;
